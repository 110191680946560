import { clsx } from "clsx"
import { FC, LabelHTMLAttributes, ReactNode } from "react"
import { overrideTailwindClasses } from "tailwind-override"
import { Tooltip } from "../Tooltip"

type Props = {
  children: ReactNode
  className?: string
  htmlFor?: LabelHTMLAttributes<HTMLLabelElement>["htmlFor"]
  isRequired?: boolean
  tooltipText?: string
  tooltipLabel?: string
}

export const Label: FC<Props> = ({
  isRequired = true,
  children,
  className = "",
  tooltipText = "",
  tooltipLabel = "",
  ...props
}) => (
  <label
    {...props}
    className={overrideTailwindClasses(clsx("uppercase font-bold text-xs flex justify-between", className))}
  >
    <div className="flex items-center">
      {children}
      {isRequired && <span className="text-gray-400 pl-1">*</span>}
      {tooltipText && <Tooltip label={tooltipLabel}>{tooltipText}</Tooltip>}
    </div>
  </label>
)
