import { GridRowSelectionModel, GridSortItem } from "@mui/x-data-grid-pro"
import { Store } from "@tanstack/react-store"
import { AssetNode } from "../components/Partials/Assets/DataGrid/AssetListDataGrid"
import {
  AssetCategory,
  AssetListDataGridQuery,
  AssetStatus,
  ListVisibilityFilter,
} from "../graphql/generated/client-types-and-hooks"

type AssetListFilterItem = {
  billingClassificationIds?: string[]
  categories?: AssetCategory[]
  isArchived?: boolean
  projectId?: string
  searchText?: string | null
  statuses?: AssetStatus[]
  taskId?: string
  userId?: string
  visibility?: ListVisibilityFilter | null
}

export const assetListStore = new Store<{
  assets: AssetNode[]
  cursor?: string | null
  filters: AssetListFilterItem
  pageInfo: { endCursor?: string | null }
  selectedAssets: AssetNode[]
  selectedRows: GridRowSelectionModel
  sort?: GridSortItem | null | undefined
  totalCount: number
}>({
  assets: [],
  cursor: null,
  filters: {
    billingClassificationIds: [],
    categories: [],
    isArchived: false,
    searchText: null,
    statuses: [],
    visibility: null,
  },
  selectedAssets: [],
  selectedRows: [],
  sort: null,
  totalCount: 0,
  pageInfo: {
    endCursor: null,
  },
})

export const assetListStoreActions = {
  initializeAssetListStore: ({
    filters,
    sort,
  }: {
    filters: AssetListFilterItem
    sort?: GridSortItem | null | undefined
  }) => {
    assetListStore.setState((state) => {
      return {
        ...state,
        assets: [],
        cursor: null,
        filters,
        sort,
        pageInfo: {
          endCursor: null,
        },
        selectedRows: [],
        totalCount: 0,
      }
    })
  },
  fetchNextPage: () => {
    assetListStore.setState((state) => {
      return {
        ...state,
        cursor: state.pageInfo.endCursor,
      }
    })
  },
  addPageData: (data: AssetListDataGridQuery["assets_2"]) => {
    if (!data) return

    assetListStore.setState((state) => {
      return {
        ...state,
        assets: data.edges.map((edge) => edge!.node),
        pageInfo: data.pageInfo,
      }
    })
  },
  setListFilterItems: (setter: AssetListFilterItem) => {
    assetListStore.setState((state) => {
      return {
        ...state,
        assets: [],
        cursor: null,
        filters: {
          ...state.filters,
          ...setter,
        },
        pageInfo: {
          endCursor: null,
        },
      }
    })
  },
  resetPagination: () => {
    assetListStore.setState((state) => {
      return {
        ...state,
        cursor: null,
        pageInfo: {
          endCursor: null,
        },
      }
    })
  },
  setItems: (assets: AssetNode[]) => {
    assetListStore.setState((state) => {
      return {
        ...state,
        assets,
        cursor: null,
        pageInfo: {
          endCursor: null,
        },
      }
    })
  },
  setSelectedRows: (rows: GridRowSelectionModel) => {
    assetListStore.setState((state) => {
      return {
        ...state,
        selectedAssets: state.assets.filter((asset) => rows.includes(asset.compositeKey)),
        selectedRows: rows,
      }
    })
  },
  setSort: (sort: GridSortItem | null | undefined) => {
    assetListStore.setState((state) => {
      return {
        ...state,
        sort,
      }
    })
  },
}
