import { useRouter } from "next/router"
import posthog from "posthog-js"
import { createContext, FC, ReactElement, useEffect, useState } from "react"
import { Drawer } from "../components/Elements/Drawer"

type DrawerStackItem = {
  drawerName: DrawerName
  component: ReactElement
  props?: Record<string, unknown>
}

type ContextValue = {
  push: (children: ReactElement, drawerName: DrawerName) => void
  pop: () => void
  clearAll: () => void
  stack: DrawerStackItem[] | null
}

export type DrawerName =
  | "AddOrEditSchedule"
  | "Asset"
  | "AssetArchive"
  | "AssetCreation"
  | "AssetEdit"
  | "CloseProject"
  | "CloseTask"
  | "Contract"
  | "ContractCreate"
  | "ContractEdit"
  | "Customer"
  | "CustomerCreate"
  | "CustomerEdit"
  | "CustomInspectionTemplate"
  | "DivisionCreate"
  | "DivisionEdit"
  | "DocumentPreview"
  | "InspectionReport"
  | "InventoryReport"
  | "Notifications"
  | "OrganizationCreate"
  | "Project"
  | "ProjectEdit"
  | "ProjectUnitReport"
  | "ReassignAsset"
  | "Task"
  | "TaskCreate"
  | "TaskEdit"
  | "TaskGroup"
  | "TaskReorder"
  | "TimeEntryDay"
  | "User"
  | "UserArchive"
  | "UserCreate"
  | "UserEdit"
  | "Vendor"
  | "VendorCreate"
  | "VendorEdit"

export const DrawerContext = createContext<ContextValue>({} as ContextValue)

export const DrawerProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const router = useRouter()
  const [stack, setStack] = useState<DrawerStackItem[] | null>(null)

  const push = (pushChildren: ReactElement, drawerName: DrawerName) => {
    posthog.capture("drawer_opened", { drawerName, ...pushChildren.props })

    setStack((previous) => {
      if (!previous) return [{ component: pushChildren, drawerName }]

      return [...previous, { component: pushChildren, drawerName }]
    })
  }

  const pop = () => {
    const drawerToPop = stack?.at(-1)
    if (drawerToPop) {
      posthog.capture("drawer_closed", { drawerName: drawerToPop?.drawerName, ...drawerToPop?.props })
    }

    setStack((previous) => {
      if (!previous) return null

      return previous.slice(0, -1)
    })
  }

  const clearAll = () => {
    setStack(null)
  }

  useEffect(() => {
    const handleRouteChange = (_: string, { shallow }: { shallow: boolean }) => {
      if (!shallow) {
        setStack(null)
      }
    }

    router.events.on("routeChangeComplete", handleRouteChange)

    return () => router.events.off("routeChangeComplete", handleRouteChange)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <DrawerContext.Provider
        value={{
          clearAll,
          pop,
          push,
          stack,
        }}
      >
        {stack?.map((drawerContent, index) => (
          <Drawer level={index} key={index}>
            {drawerContent.component}
          </Drawer>
        ))}
        {children}
      </DrawerContext.Provider>
    </>
  )
}
