import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  sub,
  endOfDay,
  startOfDay,
  parseISO,
  isValid,
  startOfMinute,
  getSeconds,
  addSeconds,
} from "date-fns"
import { zonedTimeToUtc } from "date-fns-tz"

type Duration = {
  hours: number
  minutes: number
  seconds: number
}

export function getDuration(startDate: Date, endDate: Date) {
  const duration: Duration = { hours: 0, minutes: 0, seconds: 0 }
  duration.hours = differenceInHours(startDate, endDate)

  const remainingMinutes = sub(startDate, { hours: duration.hours })
  duration.minutes = differenceInMinutes(remainingMinutes, endDate)

  const remainingSeconds = sub(remainingMinutes, { minutes: duration.minutes })
  duration.seconds = differenceInSeconds(remainingSeconds, endDate)

  Object.keys(duration).forEach((key) => (duration[key as keyof Duration] = Math.abs(duration[key as keyof Duration])))

  return duration
}

export function secondsToDuration(seconds: number) {
  const startDate = new Date(0)
  const endDate = new Date(seconds * 1000)
  return getDuration(startDate, endDate)
}

export function formatDuration(duration: Duration) {
  const formatted = { hours: "", minutes: "", seconds: "" }
  formatted.hours = `${duration.hours}h`
  formatted.minutes = `${duration.minutes}m`
  formatted.seconds = `${duration.seconds}s`
  return formatted
}

export function secondsToFormattedString(seconds: number, includeSeconds?: boolean) {
  const duration = secondsToDuration(seconds)
  const time = formatDuration(duration)
  let str = ""
  if (duration.hours) str += time.hours
  if (duration.minutes) str += " " + time.minutes
  if (!str || (includeSeconds && duration.seconds)) str += " " + time.seconds
  return str.trim()
}

export const sortByTime = (time1: string, time2: string) => {
  const [aHours, aMinutes] = time1.split(":")
  const [bHours, bMinutes] = time2.split(":")

  const aDate = new Date(new Date().setHours(+aHours, +aMinutes))
  const bDate = new Date(new Date().setHours(+bHours, +bMinutes))

  return aDate.getTime() - bDate.getTime()
}

/**
 * ## Timezone Start of Day
 * Example:
 * ```javascript
 * const startOfDayInUTC = timezoneStartOfDay("2024-05-16", "America/Chicago");
 * // Expected output: 2024-05-16T05:00:00.000Z
 * ```
 */

export class InvalidDateStringError extends Error {
  constructor() {
    super("Invalid Date string: expected `yyyy-MM-dd` format")
  }
}

export function timezoneStartOfDay(day: string, timezone: string): Date {
  const parsedDate = parseISO(day)
  if (!isValid(parsedDate)) {
    throw new InvalidDateStringError()
  }
  return zonedTimeToUtc(startOfDay(parsedDate), timezone)
}

/**
 * ## Timezone End of Day
 * ```javascript
 * const endOfDayInUTC = timezoneEndOfDay("2024-05-16", "America/Chicago");
 * // Expected output: 2024-05-17T04:59:59.999Z
 * ```
 */

export function timezoneEndOfDay(day: string, timezone: string): Date {
  const parsedDate = parseISO(day)
  if (!isValid(parsedDate)) {
    throw new InvalidDateStringError()
  }
  return zonedTimeToUtc(endOfDay(parsedDate), timezone)
}

export function roundDownToNearestMinute(date: Date) {
  return startOfMinute(date)
}

export function roundUpToNearestMinute(date: Date) {
  const seconds = getSeconds(date)
  if (seconds === 0) {
    return startOfMinute(date)
  }
  const secondsToAdd = 60 - seconds
  const roundedDate = addSeconds(date, secondsToAdd)
  return startOfMinute(roundedDate)
}
