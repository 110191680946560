import { useRouter } from "next/router"
import { useEffect, useState } from "react"

export const useIsOrganizationMenuActive = () => {
  const router = useRouter()
  const [isOrganizationMenuActive, setIsOrganizationMenuActive] = useState(false)

  useEffect(() => {
    setIsOrganizationMenuActive(router.asPath.startsWith("/organization/"))
  }, [router])

  return {
    isOrganizationMenuActive,
    setIsOrganizationMenuActive,
  }
}
