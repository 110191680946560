import { Button, ButtonProps } from "@mui/material"
import React, { ButtonHTMLAttributes, FC, ReactNode } from "react"

type Props = {
  children: ReactNode
  className?: string
  color?: ButtonProps["color"]
  disabled?: HTMLInputElement["disabled"]
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  type: ButtonHTMLAttributes<HTMLButtonElement>["type"]
}

/**
 * @deprecated Use MUI Button with variant="outlined" instead
 */
export const ButtonHollow: FC<Props> = ({ children, className = "", disabled = false, type, ...props }) => {
  return (
    <Button {...props} variant="outlined" type={type} className={className} disabled={disabled}>
      {children}
    </Button>
  )
}
