import { clsx } from "clsx"
import { FC, ReactNode } from "react"

type Props = {
  children: ReactNode
  className?: string
}

export const Table: FC<Props> = ({ className = "", children }) => {
  return (
    <div className={clsx("flex flex-col gap-y-2", "md:border-t md:border-gray-200 md:gap-y-0", className)}>
      {children}
    </div>
  )
}
