import { FC } from "react"
import { RenderIf } from "../../RenderIf"
import { graphql } from "../../../graphql/generated/gql"
import { useQuery } from "urql"
import { DivisionBadge } from "../../DivisionBadge"
import { Divider } from "@mui/material"
import { useSwitchDivision } from "../../../hooks/useSwitchDivision"

type DivisionsNavItemProps = {
  currentDivisionId: string | null
  organizationId: string
}

export type DivisionSelect = {
  selectedDivisionId: string
}

const DivisionNavItemQueryDocument = graphql(`
  query DivisionNavItemQuery {
    myDivisions {
      id
      name
      imageUrl
      isCurrentAssignment
      isPrimary
    }
  }
`)

export const DivisionsNavItem: FC<DivisionsNavItemProps> = ({ currentDivisionId, organizationId }) => {
  const { isLoading, handleSwitchDivisions } = useSwitchDivision()

  const [{ data }] = useQuery({
    query: DivisionNavItemQueryDocument,
    requestPolicy: "cache-first",
  })

  return (
    <RenderIf permissionsInclude="divisions:read">
      <nav className="px-3 space-y-4 mt-8">
        <Divider className="mb-6" />
        {data?.myDivisions?.map((division) => {
          if ((!currentDivisionId && division.isPrimary) || division.id === currentDivisionId) {
            return null
          }

          const divisionId = organizationId === division.id ? null : division.id ?? null

          return (
            <button
              key={division.id}
              className="w-full text-gray-800 hover:bg-gray-100 hover:text-gray-700 group flex items-center px-2 py-0 font-bold rounded-md transition-all text-base"
              onClick={() => handleSwitchDivisions(organizationId, divisionId)}
              disabled={isLoading}
            >
              <DivisionBadge division={division} showCurrentAssignment showDivisionTypeLabel />
            </button>
          )
        })}
      </nav>
    </RenderIf>
  )
}
