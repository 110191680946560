import { FC, ReactNode, useContext } from "react"
import { DevelopmentFeatureFlagName } from "../helpers/api/developmentFeatureFlags"
import { DevelopmentFeatureFlagContext } from "../providers/DevelopmentFeatureFlagProvider"

type Props = {
  children: ReactNode
  name: DevelopmentFeatureFlagName
  fallbackComponent?: ReactNode
}

export const DevelopmentFeatureFlag: FC<Props> = ({ name, children, fallbackComponent }) => {
  const { flagIsEnabled } = useContext(DevelopmentFeatureFlagContext)
  return flagIsEnabled(name) ? <>{children}</> : <>{fallbackComponent}</>
}
