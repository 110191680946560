import { FC, useContext } from "react"
import { useCurrentUser } from "../../../providers/PermissionsProvider/currentUserProvider"
import { SessionContext } from "../../../providers/SessionProvider"
import { DivisionLinkMenu } from "../../DivisionLinkMenu"
import { DownloadMobileAppModal } from "../../Modals/components/DownloadMobileAppModal"
import { useModalProps } from "../../Modals/hooks/useModalProps"
import { DownloadMobileAppNavItem } from "./DownloadMobileAppNavItem"
import { FeatureFlagNavItem } from "./FeatureFlagNavItem"
import NavItem, { NavItemData } from "./NavItem"
import { WhatsNewNavItem } from "./WhatsNewNavItem"

type DesktopMenuProps = {
  navigation: NavItemData[]
}

export const DesktopMenu: FC<DesktopMenuProps> = ({ navigation }) => {
  const { organizationId } = useCurrentUser()
  const downloadMobileAppModalProps = useModalProps("Download Mobile App")
  const { data } = useContext(SessionContext)

  return (
    <div className="hidden xl:flex xl:w-64 xl:flex-col xl:fixed xl:inset-y-0 text-gray-800 z-20">
      <div className="flex-1 flex flex-col min-h-0 border-r bg-white">
        <div className="border-b h-16 pt-3">
          <DivisionLinkMenu organizationId={organizationId} currentDivisionId={data?.user?.divisionId ?? null} />
        </div>
        <div className="flex-1 flex flex-col overflow-y-auto p-4 justify-between">
          <nav className="space-y-4">
            {navigation
              .filter(({ bottom }) => !bottom)
              .map((item) => (
                <NavItem item={item} isMobile={true} key={item.name} />
              ))}
          </nav>
          <nav className="mt-4 space-y-4">
            <DownloadMobileAppNavItem downloadMobileAppModalProps={downloadMobileAppModalProps} />
            <FeatureFlagNavItem />
            <WhatsNewNavItem />
            {navigation
              .filter(({ bottom }) => !!bottom)
              .map((item) => (
                <NavItem item={item} isMobile={true} key={item.name} />
              ))}
          </nav>
        </div>
      </div>
      {downloadMobileAppModalProps.isOpen && <DownloadMobileAppModal modalProps={downloadMobileAppModalProps} />}
    </div>
  )
}
