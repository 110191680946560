import { clsx } from "clsx"
import { FC, ReactNode } from "react"

type Props = {
  children: ReactNode
  label: string
}

export const Tooltip: FC<Props> = ({ label, children }) => {
  return (
    <div className="group normal-case sm:relative mx-2 rounded size-[14px] flex items-center justify-center border border-gray-500">
      <div className="text-[9px] text-gray-500">?</div>
      <div
        className={clsx(
          "absolute w-[80vw] left-[10vw] hidden z-10 rounded-md shadow-2xl p-4 border border-gray-200 bg-white",
          "group-hover:block",
          "sm:w-96 sm:top-5 sm:left-0 sm:shadow-lg"
        )}
      >
        <h6 className="text-lg font-semibold mb-2">{label}</h6>
        <div className="text-lg font-light mb-2">{children}</div>
      </div>
    </div>
  )
}
