import { ContextUser } from "../../graphql/context"
import { DecisionContext, UserProjectAssignments } from "../../providers/PermissionsProvider/PermissionsProvider"
import { SessionUser } from "../../services/auth"
import { CheckedPermission, GrantedPermission } from "../../types/Permission"
import {
  Scope,
  ScopeAll,
  ScopeAssignedProject,
  ScopeAssignedSelf,
  ScopeAssignedTask,
  ScopeSelf,
} from "../../types/Scope"
import { PickPlus } from "../../types/helpers"

type Namespace =
  | "app"
  | "asset"
  | "blackthornEmployee"
  | "divisions"
  | "fe-organizationAdmins"
  | "fe-organizationRoles"
  | "organization"
  | "project"
  | "roles"
  | "task"
  | "timeEntry"
  | "unit"
  | "unitGoal"
  | "user"

type Action =
  | "*"
  | "archive"
  | "assign"
  | "clockInOut"
  | "complete"
  | "create"
  | "delete"
  | "export"
  | "list"
  | "login"
  | "read"
  | "report"
  | "update"

type ScopedPermission = {
  namespace: Namespace
  action: Action
  scope: Scope
}

type UserExpectation = PickPlus<
  ContextUser | SessionUser,
  "id" | "organizationId" | "assignedProjectIds" | "assignedTaskIds" | "isBlackthornEmployee"
>

// Composable piece to return the filtered permissions in both `checkForPermission` and `getAllowedScopes`
const applicablePermissions = (
  currentUser: UserExpectation,
  currentUserPermissions: GrantedPermission[],
  desiredPermission: CheckedPermission
) => {
  const [desiredNamespace, desiredAction] = desiredPermission.split(":")
  const grantedPermissions = [...currentUserPermissions]

  if (currentUser?.isBlackthornEmployee) grantedPermissions.push("blackthornEmployee:*:*")

  const permissionsApplied = grantedPermissions
    .map((p) => {
      const [namespace, action, scope] = p.split(":")

      return { namespace, action, scope } as ScopedPermission
    })
    .filter((p) => p.action === desiredAction && p.namespace === desiredNamespace)

  return permissionsApplied
}

export const unscopedPermissionCheck = (
  user: UserExpectation,
  usersPermissions: GrantedPermission[],
  desiredPermission: CheckedPermission | CheckedPermission[]
) => {
  const desiredPerms = Array.isArray(desiredPermission) ? desiredPermission : [desiredPermission]
  return desiredPerms.some((perm) => applicablePermissions(user, usersPermissions, perm)?.length > 0)
}

// Returns true for the "all" scope
const handleScopeAll = () => true

// Checks if the current user is assigned to the project
const handleScopeAssignedProject = (currentUser: UserExpectation, context: DecisionContext) => {
  // Get the project IDs from the context or the current user
  const projectIds = context.projectId
    ? Array.isArray(context.projectId)
      ? context.projectId
      : [context.projectId]
    : currentUser.assignedProjectIds

  // If there are no user project assignments in the context, check if the user is assigned to the project
  if (!context.userProjectAssignments?.length) {
    return projectIds.some((pId) => isAssignedToProject(currentUser, pId))
  }

  // If there are user project assignments in the context, check if the current user can reassign the desired user (in the context) to the project
  return projectIds.some((pId) => currentUserCanReassignUserToProject(currentUser, pId, context.userProjectAssignments))
}

// Checks if the current user is targeting themselves or their organization
const handleScopeSelfOrAssignedSelf = (
  currentUser: UserExpectation,
  context: DecisionContext,
  p: ScopedPermission
) => {
  // If the namespace is "organization", check if the user is targeting their own organization
  if (p.namespace === "organization") {
    return isTargetingMyOrganization(currentUser, context.organizationId)
  }
  // Otherwise, check if the user is targeting themselves
  return isTargetingMyself(currentUser, context.userId)
}

// Checks if the current user is assigned to the task
const handleScopeAssignedTask = (currentUser: UserExpectation, context: DecisionContext) => {
  // Get the task IDs from the context
  const taskIds = Array.isArray(context.taskId) ? context.taskId : [context.taskId]
  return taskIds.some((tId) => isAssignedToTask(currentUser, tId))
}

// Checks if the current user is targeting their own organization
const handleNamespaceOrganization = (currentUser: UserExpectation, context: DecisionContext) => {
  return isTargetingMyOrganization(currentUser, context?.organizationId)
}

// Checks if the current user has the given permission
export const checkForPermission = (
  currentUser: UserExpectation,
  currentUserPermissions: GrantedPermission[],
  permission: CheckedPermission,
  context: DecisionContext = {}
) => {
  // Get the applicable permissions for the current user and the given permission
  const perms = applicablePermissions(currentUser, currentUserPermissions, permission)

  // Check each permission
  return perms.some((p) => {
    switch (p.scope) {
      case ScopeAll:
        return handleScopeAll()
      case ScopeAssignedProject:
        return handleScopeAssignedProject(currentUser, context)
      case ScopeSelf:
      case ScopeAssignedSelf:
        return handleScopeSelfOrAssignedSelf(currentUser, context, p)
      case ScopeAssignedTask:
        return handleScopeAssignedTask(currentUser, context)
      default:
        // If the namespace is "organization", check if the user is targeting their own organization
        if (p.namespace === "organization") {
          return handleNamespaceOrganization(currentUser, context)
        }
        // If none of the above conditions are met, the user does not have the permission
        return false
    }
  })
}

// Checks if the current user can reassign the user to the project
// requires 2 checks:
// 1. The currentUser has access to the project that the user is currently on
//    (optional if there are no existing user project assignments)
// 2. The currentUser has access to the project that the user is being reassigned to
const currentUserCanReassignUserToProject = (
  currentUser: UserExpectation,
  projectId: string | null = "",
  userProjectAssignments?: UserProjectAssignments | null
) => {
  const currentUserHasAccessToNewProject = isAssignedToProject(currentUser, projectId)

  // If there are no existing user project assignments,
  // only check if the currentUser is assigned to the project
  if (!userProjectAssignments) {
    return currentUserHasAccessToNewProject
  }

  // Does the currentUser have access to the project that the user is being reassigned to
  const currentUserHasAccessToCurrentProject = userProjectAssignments
    ?.filter((upa) => upa.isCurrentAssignment)
    ?.some((upa) => isAssignedToProject(currentUser, upa.projectId))

  return currentUserHasAccessToNewProject && currentUserHasAccessToCurrentProject
}

export const getAllowedScopesForUser = (
  user: ContextUser | SessionUser,
  grantedPermissions: GrantedPermission[],
  requestedPerm: CheckedPermission | CheckedPermission[]
): Scope[] => {
  const permsToCheck = Array.isArray(requestedPerm) ? requestedPerm : [requestedPerm]
  const uniqueScopes = [
    ...new Set(
      ...permsToCheck.map((requestedPermission) => allowedScopes(user, grantedPermissions, requestedPermission))
    ),
  ]
  return uniqueScopes
}

const allowedScopes = (
  currentUser: UserExpectation,
  currentUserPermissions: GrantedPermission[],
  permission: CheckedPermission
): Scope[] => {
  const perms = applicablePermissions(currentUser, currentUserPermissions, permission)
  return perms.map((p) => p.scope)
}

const isAssignedToProject = (currentUser: UserExpectation, projectId: string | null = "") => {
  return currentUser.assignedProjectIds.some((id) => id === projectId)
}

const isAssignedToTask = (currentUser: UserExpectation, taskId: string | null = "") => {
  return currentUser.assignedTaskIds.some((id) => id === taskId)
}

const isTargetingMyself = (currentUser: UserExpectation, userId: string | null = "") => {
  return userId === currentUser.id
}

const isTargetingMyOrganization = (currentUser: UserExpectation, organizationId: string | null = "") => {
  return organizationId === currentUser.organizationId
}
