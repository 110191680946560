import { Button } from "@mui/material"
import { useRouter } from "next/router"
import { SnackbarKey } from "notistack"
import { useCallback, useEffect, useRef, useState } from "react"
import { sessionKeys } from "../lib/jwtHelpers"
import { LoadingScreenOverlay } from "./Loading/LoadingScreenOverlay"
import { infoSnack } from "./Notistack/ThemedSnackbars"

const MILLISECONDS_TO_WAIT = 10 * 60 * 1000 // 10 minutes
const SECONDS_IN_A_DAY = 24 * 60 * 60

const clientBuildId = process.env.NEXT_PUBLIC_GIT_COMMIT
const clientBuildTimestamp = Number(process.env.NEXT_PUBLIC_BUILD_TIMESTAMP)

export const NewDeploymentAvailableDialog = () => {
  const router = useRouter()

  const [isUpdating, setIsUpdating] = useState(false)
  const [data, setData] = useState<{ buildId: string; buildTimestamp: number } | null>(null)

  const infoSnackRef = useRef<SnackbarKey>(0)

  useInterval(() => {
    // if the environment variables aren't set, don't run the interval
    if (!clientBuildId || !clientBuildTimestamp) return

    const getBuildInfoData = async () => {
      const accessToken = localStorage.getItem(sessionKeys.accessToken) ?? ""
      if (!accessToken) return

      const request = await fetch("/api/build-info", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      })
      if (!request.ok) {
        console.error("Failed to fetch build info")
        return
      }

      const response = await request.json()

      setData(response)
    }
    getBuildInfoData()
  }, MILLISECONDS_TO_WAIT)

  const showInfoSnack = useCallback(() => {
    infoSnackRef.current = infoSnack("A new CrewView version is available", {
      persist: true,
      className: "font-light",
      action: (
        <Button
          color="white"
          onClick={() => {
            setIsUpdating(true)
            router.reload()
          }}
          size="small"
          type="button"
          variant="text"
        >
          Update to the latest version
        </Button>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    })
  }, [router])

  useEffect(() => {
    // don't add a new snack if we already have one
    if (infoSnackRef.current) return

    // don't show the snack if we don't have the build info yet
    if (!data) return

    const { buildId: apiBuildId, buildTimestamp: apiBuildTimestamp } = data

    // don't run if we don't have anything to compare against
    if (!clientBuildId || !clientBuildTimestamp) return

    // if the local build ID matches the API build ID, we don't need to show the snack
    if (apiBuildId === clientBuildId) return

    // we only want to show the snack if the client build timestamp is more than a day old
    // this way we're not bombarding our users with all the new versions we ship
    if (+apiBuildTimestamp - clientBuildTimestamp < SECONDS_IN_A_DAY) return

    showInfoSnack()
  }, [data, router, showInfoSnack])

  if (isUpdating) {
    return <LoadingScreenOverlay />
  }

  return null
}

type IntervalFunction = () => unknown | void

function useInterval(callback: IntervalFunction, delay: number | null) {
  const savedCallback = useRef<IntervalFunction | null>(null)

  useEffect(() => {
    if (delay === null) return
    savedCallback.current = callback
  })

  useEffect(() => {
    if (delay === null) return
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current()
      }
    }
    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}
