import { format } from "date-fns"
import React from "react"
import { errorSnack } from "../Notistack/ThemedSnackbars"
import ErrorPage from "./ErrorPage"

interface ErrorBoundaryProps {
  children: React.ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  errorKey?: string
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    const timeString = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")
    const uniqueErrorKey = `${error.name}-${timeString}`
    return { hasError: !!error, errorKey: uniqueErrorKey }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Error caught by ErrorBoundary:", error)
    console.error("Error details:", errorInfo)
  }

  render() {
    if (this.state.hasError) {
      errorSnack(<div>We&apos;re sorry, something went wrong. Please try again.</div>, {
        key: this.state.errorKey,
        preventDuplicate: true,
      })

      return <ErrorPage resetError={() => this.setState({ hasError: false })} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
