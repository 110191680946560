import { styled } from "@mui/material"
import { clsx } from "clsx"
import jwt from "jsonwebtoken"
import { useRouter } from "next/router"
import { FC, useContext, useEffect, useRef, useState } from "react"
import {
  BiAlarm,
  BiBell,
  BiBug,
  BiCog,
  BiExit,
  BiMenu,
  BiMobileAlt,
  BiSolidMask,
  BiUser,
  BiUserCircle,
} from "react-icons/bi"
import { RiQuestionLine } from "react-icons/ri"
import { refreshSession } from "../../../data/api"
import { useEndAllMasqueradeSessionsForUserMutation } from "../../../graphql/generated/client-types-and-hooks"
import { secondsToFormattedString } from "../../../helpers/dateAndTime/time-utility"
import { useWindowSize } from "../../../hooks/useWindowSize"
import { expiresIn, sessionKeys } from "../../../lib/jwtHelpers"
import { NotificationsContext } from "../../../providers/NotificationsProvider"
import { useCurrentUser } from "../../../providers/PermissionsProvider/currentUserProvider"
import { SessionContext } from "../../../providers/SessionProvider"
import { StandardClaimsResponse } from "../../../services/auth"
import { URLs } from "../../../urls.config"
import { Breadcrumbs } from "../../Breadcrumbs"
import { ModalProps } from "../../Modals/hooks/useModalProps"
import { Monogram } from "../../Monogram"
import { NotificationsDrawer } from "../../Partials/Drawer/components/NotificationsDrawer"
import { DrawerContext } from "../../Partials/Drawer/providers/DrawerProvider"
import { MenuItem, QuickMenuMui } from "../../QuickMenuMui"

const PulseAnimation = styled("div")({
  "@keyframes custom-pulse": {
    from: { boxShadow: "0 0 0 0 rgba(229, 62, 62, 1)" },
    to: { boxShadow: "0 0 0 10px rgba(229, 62, 62, 0)" },
  },
  animation: "custom-pulse 2s infinite",
})

type HeaderProps = {
  setSidebarOpen: (value: boolean) => void
  downloadMobileAppModalProps: ModalProps
}

const Header: FC<HeaderProps> = ({ setSidebarOpen, downloadMobileAppModalProps }) => {
  const { logout, data, update: updateSession, claims } = useContext(SessionContext)
  const [sessionExpiresIn, setSessionExpiresIn] = useState<number | undefined>()
  const router = useRouter()
  const currentUser = useCurrentUser()
  const headerContainerRef = useRef<HTMLDivElement>(null)
  const hamburgerButtonRef = useRef<HTMLButtonElement>(null)
  const profileButtonRef = useRef<HTMLDivElement>(null)
  const masqueradeButtonRef = useRef<HTMLDivElement>(null)
  const breadcrumbsRef = useRef<HTMLDivElement>(null)
  const { width: windowWidth } = useWindowSize()
  const masquerader = data?.user?.masquerader
  const [, endAllMasqueradeSessionsForUser] = useEndAllMasqueradeSessionsForUserMutation()
  const { push } = useContext(DrawerContext)
  const { notifications } = useContext(NotificationsContext)
  const unreadNotifications = notifications?.filter((notification) => !notification.markedReadAt)
  const hasUnreadNotifications = unreadNotifications.length > 0

  async function endMasquerade() {
    const refreshToken = localStorage.getItem(sessionKeys.refreshToken)
    const accessToken = localStorage.getItem(sessionKeys.accessToken)

    if (accessToken) {
      const decoded = jwt.decode(accessToken) as StandardClaimsResponse
      await endAllMasqueradeSessionsForUser({ subjectUserId: decoded.sub })
    }

    await refreshSession(refreshToken)
    await updateSession()

    window.location.href = "/"
  }

  const profileActions: MenuItem[][] = [
    [
      {
        value: "Profile",
        onClick: () => router.push(URLs.profile + currentUser.id),
        Icon: BiUserCircle,
      },
      {
        value: "Help Center",
        onClick: () => window.open(URLs.helpCenter),
        Icon: RiQuestionLine,
      },
      {
        value: "Settings",
        onClick: () => router.push("/settings"),
        Icon: BiCog,
      },
      {
        value: "Report a Bug",
        href: URLs.reportBug,
        target: "_blank",
        Icon: BiBug,
      },
      {
        value: "Download Mobile App",
        onClick: () => downloadMobileAppModalProps.handleOpenModal(),
        Icon: BiMobileAlt,
      },
    ],
    [
      {
        value: "Log out",
        onClick: () => logout(),
        Icon: BiExit,
      },
    ],
  ]

  useEffect(() => {
    if (!windowWidth) return
    if (!breadcrumbsRef.current) return

    const breadCrumbsWidth =
      (headerContainerRef?.current?.clientWidth || 0) -
      (hamburgerButtonRef.current?.clientWidth || 0) -
      (profileButtonRef.current?.clientWidth || 0) -
      (masqueradeButtonRef.current?.clientWidth || 0) -
      20
    breadcrumbsRef.current.style.width = `${breadCrumbsWidth}px`
  }, [windowWidth])

  useEffect(() => {
    if (sessionExpiresIn && masquerader && sessionExpiresIn < 0) endMasquerade()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionExpiresIn])

  useEffect(() => {
    const interval = setInterval(async () => {
      setSessionExpiresIn(expiresIn(claims?.exp || 0))
    }, 1000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const masqueraderTimeRemaining = sessionExpiresIn || 0 > 0 ? secondsToFormattedString(sessionExpiresIn || 0) : 0

  const masqueradeMenu: MenuItem[][] = [
    [
      {
        value: `${masquerader?.firstName} ${masquerader?.lastName}`,
        Icon: BiUser,
        isDisabled: true,
      },
      {
        value: `Time remaining: ${typeof sessionExpiresIn === "number" ? masqueraderTimeRemaining : ". . ."}`,
        Icon: BiAlarm,
        isDisabled: true,
      },
    ],
    [
      {
        value: "End Session",
        onClick: endMasquerade,
        Icon: BiExit,
      },
    ],
  ]

  return (
    <>
      <div
        ref={headerContainerRef}
        className="w-full sticky top-0 z-10 flex items-center justify-between h-16 border-b bg-white"
      >
        <button
          type="button"
          className={clsx("px-4 focus:outline-none xl:hidden", masquerader ? "text-white" : "text-gray-600")}
          onClick={() => setSidebarOpen(true)}
          ref={hamburgerButtonRef}
        >
          <span className="sr-only">Open sidebar</span>
          <BiMenu className="size-8" aria-hidden="true" />
        </button>

        <div className="flex justify-between items-center md:pl-4">
          <div ref={breadcrumbsRef}>
            <Breadcrumbs />
          </div>
          <div ref={masqueradeButtonRef} className="w-14">
            {masquerader && (
              <QuickMenuMui items={masqueradeMenu} buttonShape="round" className="hover:bg-white">
                <PulseAnimation className="size-10 bg-red-100 rounded-full flex justify-center items-center animate-pulse border-2 border-red-600 hover:bg-red-200">
                  <BiSolidMask className="size-8 text-red-600" />
                </PulseAnimation>
              </QuickMenuMui>
            )}
          </div>
          <div className="px-4 flex justify-end items-center shrink" ref={profileButtonRef}>
            <div className="sm:ml-4 flex items-center xl:ml-6 gap-x-3">
              <button className="relative" onClick={() => push(<NotificationsDrawer />, "Notifications")}>
                {hasUnreadNotifications && (
                  <div className="absolute top-0 right-0 bg-white size-3 rounded-full flex justify-center items-center">
                    <span className="bg-red-500 size-2 block rounded-full">
                      <span className="sr-only">You have {unreadNotifications.length} unread notifications</span>
                    </span>
                  </div>
                )}
                <BiBell className="size-6 text-gray-800" />
              </button>
              <QuickMenuMui items={profileActions} buttonShape="round">
                <span className="sr-only">Open user menu</span>
                <Monogram user={currentUser} className="size-10" />
              </QuickMenuMui>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
