import { Cache } from "@urql/exchange-graphcache"

export const invalidateProjectCache = (cache: Cache) => {
  const fieldsToUpdate: Record<string, boolean> = {
    projects: true,
    project: true,
  }

  cache
    .inspectFields("Query")
    .filter((query) => fieldsToUpdate[query.fieldName])
    .forEach((query) => {
      cache.invalidate("Query", query.fieldName, query.arguments)
    })
}
