import { clsx } from "clsx"
import { FC, ReactNode } from "react"
import { overrideTailwindClasses } from "tailwind-override"

type Props = {
  children: ReactNode
  className?: string
}

/** @deprecated use MUI Typography component with variant="h3" instead */
export const H3: FC<Props> = ({ children, className = "", ...props }) => (
  <h3
    {...props}
    className={overrideTailwindClasses(clsx("text-2xl lg:text-3xl text-gray-800 font-bold mt-2 mb-4", className))}
  >
    {children}
  </h3>
)
