import { Cache } from "@urql/exchange-graphcache"
import { invalidateAssetCache } from "./utils/asset.util"

type DeleteArgs = {
  status?: string
  currentDivisionId?: string | null
  divisionId?: string | null
}

export const handleDeleteAsset = <T extends DeleteArgs>(_: unknown, _args: T, cache: Cache) => {
  invalidateAssetCache(cache)
}
