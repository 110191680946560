import { FC } from "react"

type CopyToClipboardProps = {
  children: React.ReactNode
  onCopy?: () => void
  text: string
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export const CopyToClipboard: FC<CopyToClipboardProps> = ({
  children,
  onCopy = () => {},
  text = "",
  ...buttonProps
}) => {
  const protocol = typeof window !== "undefined" ? `${window.location.protocol}//` : "https://"
  const host = typeof window !== "undefined" ? window.location.host : "https://app.crewview.com"

  if (text.at(0) === "/") text = `${protocol}${host}${text}`

  return (
    <button
      onClick={() => {
        navigator.clipboard.writeText(text)
        onCopy()
      }}
      type="button"
      {...buttonProps}
    >
      {children}
    </button>
  )
}
