import Head from "next/head"
import { FC } from "react"

type PageTitleProps = {
  title?: string
}

export const PageTitle: FC<PageTitleProps> = ({ title }) => {
  return (
    <Head>
      <title>{title && `${title} | `}CrewView</title>
    </Head>
  )
}
