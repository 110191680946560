import { Cache, Data, Entity, Link } from "@urql/exchange-graphcache"
import { gql } from "urql"
import {
  AssetGroupReassignmentSpecification,
  MutationTransferAssetsArgs,
} from "../../../graphql/generated/client-types-and-hooks"
import {
  Asset,
  InputMaybe,
  QueryAssetsArgs,
  QueryAssets_2Args,
  QueryAssignedAssetsArgs,
  WithTypename,
} from "../../../graphql/generated/graphcache"
import { addManyToQuery, invalidateQuery, removeManyFromQuery } from "../cacheHelpers"
import { invalidateTaskCache } from "./utils/task.util"
import {
  UserAssetUpdate,
  getTaskIdFromUserId,
  handlePreviousUserAssetCount,
  handleUserAssetCount,
} from "./utils/user.utils"
import { invalidateProjectCache } from "./utils/project.util"
import { assetListStoreActions } from "../../../stores/assetList"

type Result = {
  transferAssets: WithTypename<Asset>[]
}

/**
 * Updates the cache when calling the transferAssets mutation.
 *
 * @param result - { transferAssets: WithTypename<Asset>[] } The result of the transferAssets mutation
 * @param args - The arguments passed to the mutation { assetIds: string[], assignableId: string, assignableType: string, assetGroupReassignments: AssetGroupReassignmentSpecification[], projectIdIfTask: string
 * @param cache - The graphcache object
 */
export const transferAssets = (result: Result, args: MutationTransferAssetsArgs, cache: Cache) => {
  const { assetIds, assignableId, assignableType, assetGroupReassignments, projectIdIfTask } = args
  const assetKeys = assetIds
    .reduce((ids, id) => {
      ids.push({ id, __typename: "Asset" })
      ids.push({ id, __typename: "AssignedAsset" })
      return ids
    }, [] as Data[])
    .filter(Boolean)
  const groupIdMap = getGroupMap(assetGroupReassignments)

  invalidateTaskCache(cache)
  invalidateProjectCache(cache)
  handlePreviousUserAssetCount(assetIds, cache)
  if (assignableType === "User") {
    handleUserAssetCount(
      {
        id: assignableId,
        assetCount: assetIds.length,
      } as UserAssetUpdate,
      cache
    )
  }
  if (assignableType === 'Asset') {
    updateNestedAssets(assignableType, assignableId, cache)
  }

  updateAssetQueries(assetKeys, assignableType, assignableId, cache, projectIdIfTask)
  updateAssignmentOnEachAsset(assetIds, assignableId, assignableType, groupIdMap, cache)

  assetGroupReassignments?.forEach((reassignment) => {
    updateAssetGroups(reassignment, assignableId, assignableType, result.transferAssets, cache)
  })

  assetListStoreActions.resetPagination()
}

/**
 * Converts the asset group reassignments to a map of asset group ids
 * @param assetGroupReassignments - The asset group reassignments
 * @returns a map of asset group ids to true
 */
function getGroupMap(
  assetGroupReassignments: MutationTransferAssetsArgs["assetGroupReassignments"]
): Record<string, boolean> {
  return (
    assetGroupReassignments
      ?.flatMap((g) => g.ids || [])
      .reduce(
        (acc, id) => {
          acc[id] = true
          return acc
        },
        {} as Record<string, boolean>
      ) || {}
  )
}

type AssetQueryArgs = QueryAssetsArgs & QueryAssets_2Args & QueryAssignedAssetsArgs

/**
 * Updates the asset queries in the cache.
 * @param assetKeys - The asset keys to update
 * @param assignableType - The type of the assignable (User, Project, Task)
 * @param assignableId - The id of the assignable for the asset
 * @param cache - the graphcache object
 * @param projectIdIfTask - The project id if the assignable is a task
 */
function updateAssetQueries(
  assetKeys: Data[],
  assignableType: string,
  assignableId: string,
  cache: Cache,
  projectIdIfTask?: InputMaybe<string>
) {
  const fieldsToUpdate: Record<string, boolean> = { assets: true, assets_2: true, assignedAssets: true }
  const userTaskId = assignableType === "User" ? getTaskIdFromUserId(assignableId, cache) : null
  const assetTaskId = assignableType === "Asset" ? getTaskIdFromAssetId(assignableId, cache) : null
  const shouldInvalidateTaskCache = isCacheInvalidationRequired(assignableType, userTaskId, assetTaskId)

  cache
    .inspectFields("Query")
    .filter((query) => fieldsToUpdate[query.fieldName])
    .forEach((query) => {
      const args = query.arguments as AssetQueryArgs
      const filter = args?.filter || args || {}
      const matchesAssignment = checkAssignmentMatch(
        filter,
        assignableType,
        assignableId,
        projectIdIfTask,
        userTaskId || assetTaskId
      )

      if (matchesAssignment) {
        addManyToQuery(cache, query, assetKeys)
      } else if (query.fieldName === "assets_2" || (filter.taskId && shouldInvalidateTaskCache)) {
        invalidateQuery(cache, query)
      } else {
        removeManyFromQuery(
          cache,
          query,
          assetKeys.map((key) => cache.keyOfEntity(key))
        )
      }
    })
}

/**
 * Updates the asset nesting data
 * @param assignableType - The type of the assignable (Asset, Task, User)
 * @param assignableId - The id of the assignable for the asset
 * @param cache - the graphcache object
 */
function updateNestedAssets(
  assignableType: string,
  assignableId: string,
  cache: Cache,
) {
  if (assignableType !== "Asset") {
    return
  }
  cache
    .inspectFields("Query")
    .filter((query) => (query.fieldName === "asset"))
    .forEach((query) => {
      if (query.arguments?.id === assignableId) {
        invalidateQuery(cache, query)
      }
    })
  }

/**
 * Checks if the cache should be invalidated for a task when reassigning assets.
 * @param assignableType - The type of the assignable (User, Asset, Task)
 * @param userTaskId - The task id for the user
 * @param assetTaskId - The task id for the asset
 */
function isCacheInvalidationRequired(assignableType: string, userTaskId: string | null, assetTaskId: string | null) {
  return (assignableType === "User" && !userTaskId) || (assignableType === "Asset" && !assetTaskId)
}

/**
 * Gets the task id from the cache for a given asset id.
 * If the asset is assigned to an asset then it will recursively get the task id for the asset.
 * @param assetId The asset id to get the task id from
 * @param cache The graphcache object
 * @returns The task id for the asset
 */
function getTaskIdFromAssetId(assetId: string, cache: Cache): string | null {
  const { assetKey, assignableType } = getAssignedAssetInfo(assetId, cache)

  if (assignableType === "Task") {
    const assignedTaskId = cache.resolve(assetKey, "assignedTaskId") as string | null

    return assignedTaskId
  }
  if (assignableType === "User") {
    const userId = cache.resolve(assetKey, "assignableId") as string | null

    return userId ? getTaskIdFromUserId(userId, cache) : null
  }
  if (assignableType === "Asset") {
    const assignableId = cache.resolve(assetKey, "assignableId") as string | null

    return assignableId ? getTaskIdFromAssetId(assignableId, cache) : null
  }
  return null
}

function getAssignedAssetInfo(assetId: string, cache: Cache) {
  const assetKey = cache.keyOfEntity({ __typename: "Asset", id: assetId })
  const assignedAssetKey = cache.keyOfEntity({ __typename: "AssignedAsset", id: assetId })

  const assetAssignableType = cache.resolve(assetKey, "assignableType") as string | null
  const assignedAssetAssignableType = cache.resolve(assignedAssetKey, "assignableType") as string | null
  const isAssignedAssetInCache = !!assignedAssetAssignableType

  return {
    assetKey: isAssignedAssetInCache ? assignedAssetKey : assetKey,
    assignableType: isAssignedAssetInCache ? assignedAssetAssignableType : assetAssignableType,
  }
}

/**
 * Checks if the asset query matches the updated assignable type and id
 * @param args - The arguments from the asset query
 * @param assignableType - The type of the assignable (User, Project, Task)
 * @param assignableId - The id of the assignable for the asset
 * @param projectIdIfTask - The project id if the assignable is a task
 * @returns true if the asset query matches the updated assignable type and id
 */
function checkAssignmentMatch(
  args: QueryAssetsArgs | QueryAssets_2Args | QueryAssignedAssetsArgs,
  assignableType: string,
  assignableId: string,
  projectIdIfTask?: InputMaybe<string>,
  assignedTaskId?: string | null
): boolean {
  const { userId, projectId, taskId } = args as QueryAssetsArgs & QueryAssets_2Args & QueryAssignedAssetsArgs

  if (assignableType === "User") {
    return userId === assignableId || (!!assignedTaskId && assignedTaskId === taskId)
  }
  if (assignableType === "Task") {
    return taskId === assignableId || (!!projectIdIfTask && !!projectId && projectIdIfTask === projectId)
  }
  if (assignableType === "Asset") {
    return !!assignedTaskId && assignedTaskId === taskId
  }

  return false
}

/**
 * Updates the asset assignments to point at the new assignable type and id.
 * We will not update the asset where an asset group is being reassigned.
 * @param assetIds - The asset ids to update
 * @param assignableId - The id of the assignable for the asset
 * @param assignableType - The type of the assignable (User, Project, Task)
 * @param groupIdMap - A map of asset group ids to true
 * @param cache - The graphcache object
 **/
function updateAssignmentOnEachAsset(
  assetIds: string[],
  assignableId: string,
  assignableType: string,
  groupIdMap: Record<string, boolean>,
  cache: Cache
) {
  assetIds.forEach((assetId) => {
    if (groupIdMap?.[assetId]) return null

    cache.writeFragment(
      gql`
        fragment _ on Asset {
          id
          assignableId
          assignableType
        }
      `,
      {
        id: assetId,
        assignableId,
        assignableType,
        __typename: "Asset",
      }
    )
  })
}

/**
 * Updates the asset groups in the cache.
 * These updates rely on data not used in the backend but useful for cache updates.
 * The data is passed to the API (probs should just use that at the API instead...)
 * @param reassignment - The asset group reassignment specification
 * @param assignableId - The id of the new assignable for the asset
 * @param assignableType - The type of the new assignable for the asset
 * @param transferredAssets - The assets that are being transferred
 * @param cache - The graphcache object
 */
function updateAssetGroups(
  reassignment: AssetGroupReassignmentSpecification,
  assignableId: string,
  assignableType: string,
  transferredAssets: WithTypename<Asset>[],
  cache: Cache
) {
  const { filter, ids } = reassignment
  const newGroupKey = getGroupKey(cache, filter, assignableId, assignableType)

  if (newGroupKey) {
    const countOfCurrent = cache.resolve(newGroupKey, "count") as number | null

    const compositeKeyInCache = cache.resolve(newGroupKey, "compositeKey")
    if (compositeKeyInCache) {
      const newCount = (countOfCurrent || 0) + ids.length

      writeAssetGroup(filter, assignableId, assignableType, newCount, cache)
    } else {
      // TODO: This seems wrong. I think we want to create the asset group instead of creating the asset as an asset group.
      transferredAssets.forEach((asset) => {
        createAssetGroup(newGroupKey, asset, cache)
      })
    }
  }

  const oldGroupKey = getGroupKey(cache, filter)

  const countOfOldAssignment = cache.resolve(oldGroupKey, "count") as number | null
  const newCount = countOfOldAssignment ? countOfOldAssignment - ids.length : 0

  if (newCount > 0) {
    writeAssetGroup(filter, assignableId, assignableType, newCount, cache)
  } else {
    if (oldGroupKey) {
      removeAssetGroup(oldGroupKey, cache)
    }
  }
}

/**
 * Creates a key for the asset group
 * @param cache - the graphcache object
 * @param filter - the filter for the asset group
 * @param assignableId - the id of the new assignable for the asset
 * @param assignableType - the type of the new assignable for the asset
 * @returns A key for the asset group
 */
const getGroupKey = (
  cache: Cache,
  filter: Record<string, string | number>,
  assignableId?: string,
  assignableType?: string
) => {
  return cache.keyOfEntity({
    ...filter,
    assignableId: assignableId || filter.assignableId,
    assignableType: assignableType || filter.assignableType,
    __typename: "AssetGroup",
  })
}

/**
 * Writes the asset group to the cache
 * @param filter - The filter for the asset group
 * @param assignableId - The id of the new assignable for the asset
 * @param assignableType - The type of the new assignable for the asset
 * @param newCount - The new count for the asset group
 * @param cache - The graphcache object
 */
const writeAssetGroup = (
  filter: Record<string, string | number>,
  assignableId: string,
  assignableType: string,
  newCount: number,
  cache: Cache
) => {
  cache.writeFragment(
    gql`
      fragment AssetGroupOnAssetGroup on AssetGroup {
        assetGroupId
        assignableId
        assignableType
        status
        assetChildCount
        count
      }
    `,
    {
      ...filter,
      assignableId,
      assignableType,
      assetChildCount: newCount,
      count: newCount,
    }
  )
}

/**
 * Creates the asset group in the cache
 * This uses the assetTransferred adding it to the asset group.
 * TODO: This seems wrong. I think we want to create the asset group instead of creating the asset as an asset group. Need to fix in a future PR.
 * @param groupKey - The key of the asset group to create
 * @param assetTransferred - The asset that is being transferred
 * @param cache - The graphcache object
 */
const createAssetGroup = (groupKey: string, assetTransferred: WithTypename<Asset>, cache: Cache) => {
  cache
    .inspectFields("Query")
    .filter((query) => query.fieldName === "assetGroups")
    .forEach((query) => {
      const data = cache.resolve("Query", query.fieldKey)

      if (Array.isArray(data)) {
        data.push(assetTransferred)
        cache.link(groupKey, query.fieldKey, assetTransferred as Link<Entity>)
      }
    })
}

/**
 * Removes the asset group from the cache
 * @param groupKey - The key of the asset group to remove
 * @param cache - The graphcache object
 */
const removeAssetGroup = (groupKey: string, cache: Cache) => {
  cache
    .inspectFields("Query")
    .filter((query) => query.fieldName === "assetGroups")
    .forEach((query) => {
      const data = cache.resolve("Query", query.fieldKey) as string[]

      if (Array.isArray(data)) {
        const fromGroupCompositeKey = cache.resolve(groupKey, "compositeKey")
        const filtered = data.filter((compositeKey) => compositeKey !== `AssetGroup:${fromGroupCompositeKey}`)
        cache.link("Query", query.fieldKey, filtered)
      }
    })
}
