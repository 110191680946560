import {
  Button,
  SwipeableDrawer,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { clsx } from "clsx"
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { BiCheck, BiRefresh, BiX } from "react-icons/bi"
import { errorSnack } from "../components/Notistack/ThemedSnackbars"
import { getFeatureFlags } from "../data/api"
import { FeatureToggleSegments } from "../graphql/generated/client-types-and-hooks"
import { DevelopmentFeatureFlagName, DevelopmentFeatureFlagType } from "../helpers/api/developmentFeatureFlags"
import { FeatureToggleResponse } from "../lib/toggleService"

export type FeatureFlagContextValue = {
  flagIsEnabled: (name: DevelopmentFeatureFlagName) => boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  fetchFeatureFlags: (email?: string) => void
}

export const FEATURE_FLAG_LOCAL_OVERRIDES_LOCALSTORAGE_KEY = "feature_flag_local_override"
export const FEATURE_FLAG_SET_TYPE_LOCALSTORAGE_KEY = "feature_flag_set_type"

export const useFlags = () => useContext(DevelopmentFeatureFlagContext)

enum FeatureFlagSetType {
  Local = "local",
  ToggleService = "toggleService",
  Default = "default",
}

function getFlagSetType() {
  const string = localStorage.getItem(FEATURE_FLAG_SET_TYPE_LOCALSTORAGE_KEY)
  if (string === FeatureFlagSetType.Local) return FeatureFlagSetType.Local
  if (string === FeatureFlagSetType.Default) return FeatureFlagSetType.Default
  if (string === FeatureFlagSetType.ToggleService) return FeatureFlagSetType.ToggleService
  saveFlagSetType(FeatureFlagSetType.ToggleService)
  return FeatureFlagSetType.ToggleService
}

function saveFlagSetType(flagSetType: FeatureFlagSetType) {
  localStorage.setItem(FEATURE_FLAG_SET_TYPE_LOCALSTORAGE_KEY, flagSetType)
}

function getFlagLocalOverrides(): Map<DevelopmentFeatureFlagName, boolean> {
  const jsonString = localStorage.getItem(FEATURE_FLAG_LOCAL_OVERRIDES_LOCALSTORAGE_KEY)
  if (!jsonString) return new Map<DevelopmentFeatureFlagName, boolean>()
  const mapEntriesArray: [DevelopmentFeatureFlagName, boolean][] = JSON.parse(jsonString)
  const reconstructedMap = new Map(mapEntriesArray)
  return reconstructedMap
}

function saveFlagLocalOverrides(localOverrides: Map<DevelopmentFeatureFlagName, boolean>) {
  const mapEntriesArray = Array.from(localOverrides.entries())
  const jsonString = JSON.stringify(mapEntriesArray)
  localStorage.setItem(FEATURE_FLAG_LOCAL_OVERRIDES_LOCALSTORAGE_KEY, jsonString)
}

export const DevelopmentFeatureFlagContext = createContext<FeatureFlagContextValue>({} as FeatureFlagContextValue)

export const DevelopmentFeatureFlagProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [flagSetType, setFlagSetType] = useState<FeatureFlagSetType>(getFlagSetType())
  const [flagOverrides, setFlagOverrides] = useState<Map<DevelopmentFeatureFlagName, boolean>>(getFlagLocalOverrides())
  const [flagResponse, setFlagResponse] = useState<FeatureToggleResponse>()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    fetchFeatureFlags()
  }, [isOpen])

  useEffect(() => {
    saveFlagSetType(flagSetType)
  }, [flagSetType])

  useEffect(() => {
    saveFlagLocalOverrides(flagOverrides)
  }, [flagOverrides])

  const handleKeypress = (event: KeyboardEvent) => {
    if (event.repeat) return
    //Keypress to toggle the feature flag drawer is Shift + Ctrl + Cmd + f
    const isRightKeyCombination = event.shiftKey && event.ctrlKey && event.metaKey && event.key === "F"
    if (isRightKeyCombination) setIsOpen((previous) => !previous)
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeypress)
    return () => document.removeEventListener("keydown", handleKeypress)
  })

  async function fetchFeatureFlags(email?: string) {
    const data = await getFeatureFlags(email)
    setFlagResponse(data)
  }

  // Always use the client version of the feature flags for evaluation
  const flagIsEnabled = useCallback(
    (name: DevelopmentFeatureFlagName, passedInFlags?: DevelopmentFeatureFlagType[]) => {
      const flags = passedInFlags || flagResponse?.flags
      const flag = flags?.find((f) => f.name === name)
      if (!flag) return false
      // First Check if Local overrides are set
      if (flagSetType === FeatureFlagSetType.Local) {
        const override = flagOverrides.get(name)
        return !!override
      }
      // Second Check if Default overrides are set
      if (flagSetType === FeatureFlagSetType.Default) {
        return flag.defaultEnabled
      }
      // Third fallback to Toggle Service
      // If there is no toggle service response return default
      if (!flag.evaluationResponse) return flag.defaultEnabled
      // If toggle service responds with an error return default
      if (flag.evaluationResponse.error) return flag.defaultEnabled
      // If toggle service responds with data, return evaluation result
      if (flag.evaluationResponse.data) return flag.evaluationResponse.data.enabled
      // otherwise return default
      return flag.defaultEnabled
    },
    [flagResponse, flagOverrides, flagSetType]
  )

  const isPublicBeta = flagResponse?.featureToggleSegments?.includes(FeatureToggleSegments.PublicBeta)
  const isDeveloperBeta = flagResponse?.featureToggleSegments?.includes(FeatureToggleSegments.DeveloperBeta)
  const toggleServiceConfigured = flagResponse?.toggleServiceConfigured
  const toggleServiceOnline = flagResponse?.toggleServiceOnline

  const toggleLocalOverride = (name: DevelopmentFeatureFlagName, enabled: boolean) => {
    setFlagOverrides((current) => {
      const map = new Map(current)
      return map.set(name, enabled)
    })
  }
  const toggleAllLocalOverrides = (enabled: boolean) => {
    if (!flagResponse) return errorSnack("Unable to toggle all flags")
    const mapEntriesArray: [DevelopmentFeatureFlagName, boolean][] = flagResponse.flags.map((flag) => [
      flag.name,
      enabled,
    ])
    const map: Map<DevelopmentFeatureFlagName, boolean> = new Map(mapEntriesArray)
    setFlagOverrides(map)
  }
  const matchLocalOverridesToToggleService = () => {
    if (!flagResponse) return errorSnack("Unable to toggle all flags")
    const mapEntriesArray: [DevelopmentFeatureFlagName, boolean][] = flagResponse.flags
      .filter((flag) => flag.evaluationResponse?.data)
      .map((flag) => [flag.name, !!flag.evaluationResponse?.data?.enabled])
    const map: Map<DevelopmentFeatureFlagName, boolean> = new Map(mapEntriesArray)
    setFlagOverrides(map)
  }

  const betaLine = (title: string, bool: boolean) => (
    <div className="flex items-center">
      <Typography variant="caption">{title}</Typography> <Switch disabled checked={bool} />
    </div>
  )

  const statusLine = (title: string, bool: boolean) => (
    <div className="flex items-center">
      <Typography variant="caption">{title}</Typography>{" "}
      {bool ? <BiCheck className="size-6 text-green-600" /> : <BiX className="size-6 text-red-600" />}
    </div>
  )

  return (
    <DevelopmentFeatureFlagContext.Provider value={{ flagIsEnabled, setIsOpen, fetchFeatureFlags }}>
      {children}
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        PaperProps={{ classes: { root: "w-full max-w-lg" } }}
      >
        <div className="p-8">
          <div className="flex items-start justify-between">
            <header className="font-bold text-3xl">Feature Flags</header>
          </div>
          {flagResponse && (
            <div className="flex gap-4">
              <div>
                {betaLine("Dev Beta:", !!isDeveloperBeta)}
                {betaLine("Public Beta:", !!isPublicBeta)}
              </div>
              <div>
                {statusLine("Toggle Service Configured:", !!toggleServiceConfigured)}
                {statusLine("Toggle Service Online:", !!toggleServiceOnline)}
              </div>
              <Button onClick={() => fetchFeatureFlags()}>
                <BiRefresh className="size-8 " />
              </Button>
            </div>
          )}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Local Override</TableCell>
                  <TableCell>Toggle Service</TableCell>
                  <TableCell>Default</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell className={clsx(flagSetType === FeatureFlagSetType.Local && "bg-blue-50")}>
                    <Button
                      variant="outlined"
                      disabled={flagSetType === FeatureFlagSetType.Local}
                      onClick={() => {
                        setFlagSetType(FeatureFlagSetType.Local)
                        saveFlagSetType(FeatureFlagSetType.Local)
                      }}
                    >
                      {flagSetType === FeatureFlagSetType.Local ? "Active" : "Apply"}
                    </Button>
                  </TableCell>
                  <TableCell className={clsx(flagSetType === FeatureFlagSetType.ToggleService && "bg-blue-50")}>
                    <Button
                      variant="outlined"
                      disabled={flagSetType === FeatureFlagSetType.ToggleService}
                      onClick={() => {
                        setFlagSetType(FeatureFlagSetType.ToggleService)
                        saveFlagSetType(FeatureFlagSetType.ToggleService)
                      }}
                    >
                      {flagSetType === FeatureFlagSetType.ToggleService ? "Active" : "Apply"}
                    </Button>
                  </TableCell>
                  <TableCell className={clsx(flagSetType === FeatureFlagSetType.Default && "bg-blue-50")}>
                    <Button
                      variant="outlined"
                      disabled={flagSetType === FeatureFlagSetType.Default}
                      onClick={() => {
                        setFlagSetType(FeatureFlagSetType.Default)
                        saveFlagSetType(FeatureFlagSetType.Default)
                      }}
                    >
                      {flagSetType === FeatureFlagSetType.Default ? "Active" : "Apply"}
                    </Button>
                  </TableCell>
                </TableRow>
                {flagResponse?.flags?.map((row) => {
                  return (
                    <TableRow key={row.name}>
                      <TableCell>
                        {flagIsEnabled(row.name) ? (
                          <div className="size-2 rounded-full bg-green-600"></div>
                        ) : (
                          <div className="size-2 rounded-full bg-gray-100"></div>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography>{row.name}</Typography>
                      </TableCell>
                      <TableCell className={clsx(flagSetType === FeatureFlagSetType.Local && "bg-blue-50")}>
                        <Switch
                          disabled={flagSetType !== FeatureFlagSetType.Local}
                          checked={flagOverrides.get(row.name)}
                          onChange={(_e, enabled) => toggleLocalOverride(row.name, enabled)}
                        />
                      </TableCell>
                      <TableCell className={clsx(flagSetType === FeatureFlagSetType.ToggleService && "bg-blue-50")}>
                        {row.evaluationResponse?.data?.enabled && (
                          <BiCheck
                            className={clsx(
                              "size-8",
                              flagSetType === FeatureFlagSetType.ToggleService ? "text-green-600" : "text-gray-200"
                            )}
                          />
                        )}
                        {row.evaluationResponse?.error && <BiX className="size-8 text-red-600" />}
                      </TableCell>
                      <TableCell className={clsx(flagSetType === FeatureFlagSetType.Default && "bg-blue-50")}>
                        {row.defaultEnabled && (
                          <BiCheck
                            className={clsx(
                              "size-8",
                              flagSetType === FeatureFlagSetType.Default ? "text-green-600" : "text-gray-200"
                            )}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div>
            <Typography className="pt-5 pb-2">Local Override Quick Actions</Typography>
            <div className="flex gap-3">
              <Button
                disabled={flagSetType !== FeatureFlagSetType.Local}
                variant="contained"
                color="secondary"
                onClick={() => toggleAllLocalOverrides(true)}
              >
                All on
              </Button>
              <Button
                disabled={flagSetType !== FeatureFlagSetType.Local}
                variant="contained"
                color="secondary"
                onClick={() => toggleAllLocalOverrides(false)}
              >
                All off
              </Button>
              <Button
                disabled={flagSetType !== FeatureFlagSetType.Local}
                variant="contained"
                color="secondary"
                onClick={() => matchLocalOverridesToToggleService()}
              >
                Match Toggle Service
              </Button>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    </DevelopmentFeatureFlagContext.Provider>
  )
}
