import { ResponseError } from "./api/errors"

export function isNil(value: unknown) {
  return value === undefined || value === null
}

export function notNil(value: unknown) {
  return value !== undefined && value !== null
}

export function requireFields<T extends Record<string, unknown>>(requiredFields: string[], ...args: T[]): T {
  const obj = args.reduce((a, e) => ({ ...a, ...e }), {} as T)
  for (const key of requiredFields) {
    if (isNil(obj[key])) {
      throw new ResponseError(`Missing required field ${key}`, 400)
    }
  }
  return obj
}

export function omitNil<T extends Record<string, unknown>>(obj: T): Partial<T> {
  return Object.fromEntries(Object.entries(obj).filter(([_key, value]) => value != null)) as Partial<T>
}

export function groupBy<T, K extends PropertyKey>(collection: T[], iteratee: (item: T) => K): Record<K, T[]> {
  return collection.reduce(
    (result, value) => {
      const key = iteratee(value)
      if (!result[key]) result[key] = []
      result[key].push(value)
      return result
    },
    {} as Record<K, T[]>
  )
}

export function notNull<T>(value: T | null): T | undefined {
  return value === null ? undefined : (value as T)
}

export function optionalConnect<T>(id: T) {
  if (id === null) return { disconnect: true }
  if (id) return { connect: { id } }
  return undefined
}

export function optionalCreate<T>(args: T) {
  if (args) return { create: args }
  return undefined
}

export function connect<T>(id: T) {
  return { connect: { id } }
}

export function isOneDimensional(array: unknown[]) {
  return array.every((entry) => !Array.isArray(entry))
}

export function uniqBy<T>(array: T[], getKey: (e: T) => unknown) {
  return [...new Map(array.map((x) => [getKey(x), x])).values()]
}

export async function errorData<T>(promise: Promise<T>): Promise<{ error: null | Error; data: T | null }> {
  try {
    const data = await promise
    return { error: null, data }
  } catch (error) {
    if (error instanceof Error) {
      return { error, data: null }
    } else {
      const errorMessage = typeof error === "string" ? error : JSON.stringify(error, Object.getOwnPropertyNames(error))
      return { error: new Error(`Caught an exception: ${errorMessage}`), data: null }
    }
  }
}

export function parseString<T extends string | undefined>(value: string | string[] | undefined): T {
  if (typeof value === "string") {
    return value as T
  }
  return undefined as T
}