import React, { FC, ReactNode, useState } from "react"
import { Breadcrumb, LabelSubstitution } from "../components/Breadcrumbs"

type BreadcrumbsProviderProps = {
  children: ReactNode
}

type BreadcrumbsReturnType = {
  additionalBreadcrumbs?: Breadcrumb[]
  countSegments?: number
  isLoading: boolean
  labelSubstitutions?: LabelSubstitution[]
  setAdditionalBreadcrumbs: (value: Breadcrumb[]) => void
  setCountSegments: (value: number) => void
  setIsLoading: (value: boolean) => void
  setLabelSubstitutions: (value: LabelSubstitution[]) => void
}

const BreadcrumbsContext = React.createContext({} as BreadcrumbsReturnType)

const BreadcrumbsProvider: FC<BreadcrumbsProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [additionalBreadcrumbs, setAdditionalBreadcrumbs] = useState<Breadcrumb[]>([])
  const [labelSubstitutions, setLabelSubstitutions] = useState<LabelSubstitution[]>([])
  const [countSegments, setCountSegments] = useState(1)

  return (
    <BreadcrumbsContext.Provider
      value={{
        additionalBreadcrumbs,
        countSegments,
        isLoading,
        labelSubstitutions,
        setAdditionalBreadcrumbs,
        setCountSegments,
        setIsLoading,
        setLabelSubstitutions,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  )
}

export { BreadcrumbsContext, BreadcrumbsProvider }
