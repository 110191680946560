import { clsx } from "clsx"
import { FC } from "react"

type Props = {
  className?: string
}

export const SkeletonIcon: FC<Props> = ({ className }) => (
  <div className={clsx("size-8 bg-gray-200 rounded-full", className)} />
)
