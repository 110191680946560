import { clsx } from "clsx"
import Head from "next/head"
import { useRouter } from "next/router"
import { FC, ReactNode, useState } from "react"
import { BillingStatus } from "../../graphql/generated/graphcache"
import { useOrganizationBillingStatus } from "../../hooks/useOrganizationBillingStatus"
import { DownloadMobileAppModal } from "../Modals/components/DownloadMobileAppModal"
import { useModalProps } from "../Modals/hooks/useModalProps"
import { PageTitle } from "../PageTitle"
import ErrorBoundary from "../common/ErrorBoundary"
import { BillingPastDueBanner } from "./Elements/BillingPastDueBanner"
import Header from "./Header/Header"
import Navbar from "./Navbar/Navbar"

type Props = {
  children: ReactNode
  title?: string
  useDataGridStyles?: boolean
}

const Layout: FC<Props> = ({ children, title = "Admin", useDataGridStyles = false }) => {
  const router = useRouter()
  const isSchedulePage = router.route.includes("projects/[_project_id]/schedule")

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { organizationBillingStatus } = useOrganizationBillingStatus()
  const downloadMobileAppModalProps = useModalProps("Download Mobile App")

  return (
    <>
      <PageTitle title={title || "CrewView by Blackthorn Software"} />
      <Head>
        {process.env.NEXT_PUBLIC_IS_PRODUCTION !== "true" && (
          <link rel="shortcut icon" href="/favicon-development.ico" />
        )}
      </Head>

      {organizationBillingStatus !== BillingStatus.DisabledDueToNonPayment && (
        <div className="min-h-screen">
          <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <div className="xl:pl-64 flex flex-col">
            <Header setSidebarOpen={setSidebarOpen} downloadMobileAppModalProps={downloadMobileAppModalProps} />
            {/* z-0 here prevents this main section from overlapping header elements */}
            <main className="flex-1 z-0">
              <div className={clsx("bg-white", useDataGridStyles ? "pt-8" : "py-8")}>
                <div
                  className={clsx(
                    "mx-auto px-4 sm:px-6 md:px-8",
                    !isSchedulePage && "max-w-7xl",
                    useDataGridStyles ? "" : "mb-12 md:mb-24"
                  )}
                >
                  <ErrorBoundary>{children}</ErrorBoundary>
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
      <BillingPastDueBanner />
      {downloadMobileAppModalProps.isOpen && <DownloadMobileAppModal modalProps={downloadMobileAppModalProps} />}
    </>
  )
}

export default Layout
