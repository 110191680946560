import { useEffect } from "react"
import { CombinedError } from "urql"
import { errorSnack } from "../components/Notistack/ThemedSnackbars"

export const useHandleError = (error: Error | CombinedError | undefined | null, message: string) => {
  useEffect(() => {
    if (error) {
      errorSnack(message)
      console.error(error)
    }
  }, [error, message])
}
