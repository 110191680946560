import { useState, useCallback, useContext } from "react"
import { useSwitchUserDivisionMutation } from "../graphql/generated/client-types-and-hooks"
import { SessionContext } from "../providers/SessionProvider"
import { sessionKeys } from "../lib/jwtHelpers"
import { useRouter } from "next/router"
import { errorSnack } from "../components/Notistack/ThemedSnackbars"

export function useSwitchDivision() {
  const [isLoading, setIsLoading] = useState(false)
  const [, switchUserDivision] = useSwitchUserDivisionMutation()
  const { data, update: updateSession } = useContext(SessionContext)
  const router = useRouter()

  const currentDivisionId = data?.user?.divisionId

  const handleSwitchDivisions = useCallback(
    async (organizationId: string, selectedDivisionId: string | null) => {
      setIsLoading(true)
      const { data: accessData } = await switchUserDivision({
        organizationId,
        divisionId: selectedDivisionId,
        currentDivisionId: currentDivisionId,
      })
      const token = accessData?.switchDivision?.accessToken
      if (token) {
        localStorage.setItem(sessionKeys.accessToken, token)
        await updateSession()
        window.history.pushState({ path: "/" }, "", "/")
        router.reload()
      } else {
        errorSnack("Unable to switch division")
        setIsLoading(false)
      }
    },
    [currentDivisionId, switchUserDivision, updateSession, router]
  )

  return {
    isLoading,
    setIsLoading,
    handleSwitchDivisions,
  }
}
