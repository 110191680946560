import { Cache } from "@urql/exchange-graphcache"
import { gql } from "urql"

export const invalidateTaskCache = (cache: Cache) => {
  const fieldsToUpdate: Record<string, boolean> = {
    taskList: true,
    task: true,
  }

  cache
    .inspectFields("Query")
    .filter((query) => fieldsToUpdate[query.fieldName])
    .forEach((query) => {
      cache.invalidate("Query", query.fieldName, query.arguments)
    })
}

const taskFragment = gql`
  fragment _ on Task {
    id
    projectId
    userCount
    assetCount
    assignees {
      id
      isCurrentAssignment
      projectId
      taskId
      userId
      project {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`
const taskListFragment = gql`
  fragment _ on TaskListItem {
    id
    taskId
    projectId
    userCount
    assetCount
    assignees {
      id
      isCurrentAssignment
      projectId
      taskId
      userId
      project {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`

/**
 * Sets the asset count for a task
 * @param taskId The task id to update
 * @param delta The change in the asset count
 * @param cache The graphcache object
 */
export const setTaskAssetCount = (taskId: string, delta: number, cache: Cache) => {
  const assetCount = getTaskAssetCount(taskId, cache)
  const assetCountResult = assetCount + delta >= 0 ? assetCount + delta : 0

  cache.writeFragment(taskFragment, {
    id: taskId,
    assetCount: assetCountResult,
    __typename: "Task",
  })

  cache.writeFragment(taskListFragment, {
    id: taskId,
    assetCount: assetCountResult,
    __typename: "TaskListItem",
  })
}

export const invalidateTaskAssetCount = (taskId: string, cache: Cache) => {
  const taskKey = cache.keyOfEntity({ __typename: "Task", id: taskId })
  cache.invalidate(taskKey, "assetCount")

  const taskListKey = cache.keyOfEntity({ __typename: "TaskListItem", id: taskId })
  cache.invalidate(taskListKey, "assetCount")
}

const getTaskAssetCount = (taskId: string, cache: Cache): number => {
  const taskKey = cache.keyOfEntity({ __typename: "Task", id: taskId })
  const assetCount = cache.resolve(taskKey, "assetCount") as number | null
  if (!assetCount && typeof assetCount === "number") {
    const taskListKey = cache.keyOfEntity({ __typename: "TaskListItem", id: taskId })
    const assetListCount = cache.resolve(taskListKey, "assetCount") as number | null

    return assetListCount || 0
  }

  return assetCount || 0
}
