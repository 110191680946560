import { clsx } from "clsx"
import { FC } from "react"
import { BiBuildings } from "react-icons/bi"
import { Organization } from "../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../types/helpers"

type OrganizationImageProps = {
  className?: string
  organization?: PickPlus<Organization, "name" | "imageUrl"> | null
}

export const OrganizationImage: FC<OrganizationImageProps> = ({ className = "size-20", organization }) => {
  const classes = clsx("border border-gray-300 flex items-center justify-center rounded object-cover", className)

  if (organization?.imageUrl) {
    return <img alt={organization?.name || "Organization Image"} className={classes} src={organization.imageUrl} />
  }

  return (
    <div className={`${classes} bg-gray-100`}>
      <BiBuildings />
    </div>
  )
}
