import { ReactNode, useState } from "react"

export type ModalProps = {
  contentLabel: ReactNode
  isOpen: boolean
  handleOpenModal: () => void
  handleCloseModal: () => void
}

export const useModalProps = (contentLabel: ReactNode = ""): ModalProps => {
  const [isOpen, setIsOpen] = useState(false)

  return {
    contentLabel,
    isOpen,
    handleOpenModal: () => setIsOpen(true),
    handleCloseModal: () => setIsOpen(false),
  }
}
