import jwt from "jsonwebtoken"
import { useRouter } from "next/router"
import posthog from "posthog-js"
import { FC, ReactNode, createContext, useEffect, useState } from "react"
import { fetchSession, login, logout as logoutSession, refreshSession } from "../data/api"
import { expiresIn, sessionKeys } from "../lib/jwtHelpers"
import { Logger } from "../lib/logger"
import { SessionUser, StandardClaimsResponse } from "../services/auth"
import {
  FEATURE_FLAG_LOCAL_OVERRIDES_LOCALSTORAGE_KEY,
  FEATURE_FLAG_SET_TYPE_LOCALSTORAGE_KEY,
} from "./DevelopmentFeatureFlagProvider"
import { PERMISSIONS_EXPLORER_LOCALSTORAGE_KEY } from "./PermissionsProvider/PermissionsExplorer"

type SessionContextProviderProps = {
  children: ReactNode
}

export type SessionContextValue = {
  status: "unauthenticated" | "loading" | "authenticated"
  data?: { user: SessionUser | undefined } | null
  update: () => null | void | Promise<void>
  logout: (options?: { allSessions: boolean }) => void
  hasLoginToken: boolean
  claims?: StandardClaimsResponse | undefined
  invalidToken?: boolean
}

export const SessionContext = createContext<SessionContextValue>({
  status: "loading",
  update: () => null,
  logout: () => null,
  hasLoginToken: false,
})

export const SessionProvider: FC<SessionContextProviderProps> = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false)
  const router = useRouter()
  const [status, setStatus] = useState<SessionContextValue["status"]>("loading")
  const [data, setData] = useState<{ user: SessionUser | undefined }>()
  const [claims, setClaims] = useState<StandardClaimsResponse | undefined>()
  const token = (router.query.login_token || "") as string
  const decoded = jwt.decode(token) as StandardClaimsResponse
  const hasLoginToken = decoded && decoded.scope === "login"
  const [invalidToken, setInvalidToken] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (hasMounted) {
      getSession()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMounted, token])

  useEffect(() => {
    if (status === "authenticated") {
      posthog.identify(data?.user?.id, {
        email: data?.user?.email,
        name: `${data?.user?.firstName} ${data?.user?.lastName}`,
        organizationId: data?.user?.organizationId,
      })
    }
  }, [status, data])

  async function getSession() {
    try {
      if (data?.user) return
      // The following order matters
      if (hasLoginToken)
        await login({ token }).catch((error) => {
          setInvalidToken(true)
          throw error
        })

      // 1. First check the refresh token
      const refreshToken = localStorage.getItem(sessionKeys.refreshToken)

      const NewAuthSignedOut = !refreshToken

      if (NewAuthSignedOut) return setStatus("unauthenticated")

      // 2. Then check the access token
      const accessToken = localStorage.getItem(sessionKeys.accessToken)

      // 3. Refresh
      if (!accessToken) {
        const response = await refreshSession(refreshToken)
        if (response.status === "unauthenticated") return setStatus("unauthenticated")
      } else {
        const claimsResponse = jwt.decode(accessToken) as StandardClaimsResponse
        const expiresInSeconds = expiresIn(claimsResponse.exp)
        setClaims(claimsResponse)
        // If regular session, always refresh
        // If masqueraded session refresh only if expired. The refresh token still belongs to the masquerader so on expiration the session will revert which is the expected behavior
        if (!claimsResponse.masqueraderId || expiresInSeconds < 1) {
          const response = await refreshSession(refreshToken)
          if (response.status === "unauthenticated") return setStatus("unauthenticated")
        }
      }

      // 4. Then get the user session
      const session = await fetchSession()
      setData({ ...session })
      if (session.user.id) setStatus("authenticated")
    } catch (error) {
      Logger.error(error)
      setStatus("unauthenticated")
    }
  }

  async function logout(options = {}) {
    try {
      await logoutSession(options)

      // Set the location to / so we don't get a ?return_to query param
      // If the user logged out on purpose we shouldn't assume that the
      // same user will log back in
      router.replace("/")
      setStatus("unauthenticated")
      setData(undefined)

      posthog.reset()
    } catch (error) {
      Logger.error(error)
      window.location.href = "/"
    } finally {
      localStorage.removeItem(PERMISSIONS_EXPLORER_LOCALSTORAGE_KEY)
      localStorage.removeItem(FEATURE_FLAG_LOCAL_OVERRIDES_LOCALSTORAGE_KEY)
      localStorage.removeItem(FEATURE_FLAG_SET_TYPE_LOCALSTORAGE_KEY)
      localStorage.removeItem(sessionKeys.accessToken)
      localStorage.removeItem(sessionKeys.refreshToken)
    }
  }

  const masquerader = data?.user?.masquerader

  const value: SessionContextValue = { status, data, update: getSession, logout, claims, hasLoginToken, invalidToken }

  return (
    <SessionContext.Provider value={value}>
      {masquerader ? <div className="border-x-8 border-red-500">{children}</div> : children}
    </SessionContext.Provider>
  )
}
