import { setUser } from "@sentry/nextjs"
import { createContext, FC, ReactNode, useContext } from "react"
import { SessionUser } from "../../services/auth"
import { SessionContext } from "../SessionProvider"

type ContextProps = {
  currentUser: SessionUser
}

type CurrentUserProviderProps = {
  children: ReactNode
}

declare global {
  interface Window {
    FS: {
      identify(a: string, b: unknown): void
    }
  }
}

const CurrentUserContext = createContext<ContextProps>({} as ContextProps)

export const CurrentUserProvider: FC<CurrentUserProviderProps> = ({ children }) => {
  const { data: sessionData } = useContext(SessionContext)

  if (sessionData?.user) {
    const { user } = sessionData
    const userContext = user.email ? { email: user.email } : { id: user.id }

    setUser(userContext)

    return (
      <CurrentUserContext.Provider
        value={{
          currentUser: user,
        }}
      >
        {children}
      </CurrentUserContext.Provider>
    )
  }

  return null // loading...
}

export const useCurrentUser = () => {
  const { currentUser } = useContext(CurrentUserContext)
  return currentUser
}
