import { Dialog, Transition } from "@headlessui/react"
import { Box, Button, Typography } from "@mui/material"
import Link from "next/link"
import { useRouter } from "next/router"
import { FC, Fragment, useContext, useEffect } from "react"
import { BiExit, BiXCircle } from "react-icons/bi"
import { SessionContext } from "../../../providers/SessionProvider"
import { FeatureFlagNavItem } from "./FeatureFlagNavItem"
import Logo from "./Logo"
import NavItem, { NavItemData } from "./NavItem"
import { NavsProps } from "./Navbar"
import { WhatsNewNavItem } from "./WhatsNewNavItem"
import { DivisionsNavItem } from "./DivisionsNavItem"
import { DevelopmentFeatureFlag } from "../../DevelopmentFeatureFlag"
import { useCurrentUser } from "../../../providers/PermissionsProvider/currentUserProvider"

type MobileMenuProps = {
  navigation: NavItemData[]
}

export const MobileMenu: FC<NavsProps & MobileMenuProps> = ({ sidebarOpen, setSidebarOpen, navigation }) => {
  const { organizationId } = useCurrentUser()
  const router = useRouter()
  const { data, logout } = useContext(SessionContext)
  useEffect(() => {
    const handleRouteChange = () => {
      setSidebarOpen(false)
    }

    router.events.on("routeChangeStart", handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events])

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 flex z-40 xl:hidden" onClose={setSidebarOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600/75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-sm w-full pt-3 pb-4 bg-white">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex items-center justify-center size-10 rounded-full focus:outline-none"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <BiXCircle className="size-8 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <Link href="/organization/details" className="px-3 w-full">
              <Logo className="max-w-[80vw]" />
            </Link>
            <div className="mt-4 flex-1 h-0 overflow-y-auto flex flex-col justify-between">
              <Box>
                <nav className="px-3 space-y-4">
                  {navigation
                    .filter(({ bottom }) => !bottom)
                    .map((item) => (
                      <NavItem item={item} isMobile={true} key={item.name} />
                    ))}
                </nav>
                <DevelopmentFeatureFlag name="Divisions">
                  <DivisionsNavItem
                    organizationId={organizationId}
                    currentDivisionId={data?.user?.divisionId ?? null}
                  />
                </DevelopmentFeatureFlag>
              </Box>
              <nav className="mt-4 px-3 space-y-4">
                <FeatureFlagNavItem handleClick={() => setSidebarOpen(false)} />
                {navigation
                  .filter(({ bottom }) => !!bottom)
                  .map((item) => (
                    <NavItem item={item} isMobile={true} key={item.name} />
                  ))}
                <WhatsNewNavItem />

                <Button className="flex pl-2 pr-10" onClick={() => logout()}>
                  <BiExit className="shrink-0 size-6 mr-3 text-gray-700" />
                  <Typography className="text-base text-gray-700 font-bold">Log out</Typography>
                </Button>
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="shrink-0 w-14" aria-hidden="true"></div>
      </Dialog>
    </Transition.Root>
  )
}
