import { clsx } from "clsx"
import { FC, ReactNode } from "react"

type Props = {
  children: ReactNode
  className?: string
}

export const ColumnTitles: FC<Props> = ({ children, className }) => (
  <div className={clsx("flex gap-x-6 mb-4 overflow-x-auto pb-4", "md:grid md:grid-cols-12 md:pb-0", className)}>
    {children}
  </div>
)
