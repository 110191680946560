import { Cache } from "@urql/exchange-graphcache"
import { gql } from "urql"
import { User, WithTypename } from "../../../../graphql/generated/graphcache"

export type UserWithPreviousTaskId = WithTypename<User> & {
  previousTaskId?: string
}

export function invalidateUsersListCache(cache: Cache) {
  const fieldsToUpdate: Record<string, boolean> = {
    usersList: true,
  }

  cache
    .inspectFields("Query")
    .filter((query) => fieldsToUpdate[query.fieldName])
    .forEach((query) => {
      cache.invalidate("Query", query.fieldName, query.arguments)
    })
}

export type UserAssetUpdate = {
  id: string
  assetCount: number
}

export function handlePreviousUserAssetCount(assetIds: string[], cache: Cache) {
  assetIds.forEach((assetId) => {
    const assetKey = cache.keyOfEntity({ __typename: "AssignedAsset", id: assetId })

    const prevAssignableId = cache.resolve(assetKey, "assignableId") as string
    const prevAssignableType = cache.resolve(assetKey, "assignableType") as string

    if (prevAssignableType === "User" && !!prevAssignableId) {
      const prevUserKey = cache.keyOfEntity({ __typename: "User", id: prevAssignableId })
      const prevAssetCount = cache.resolve(prevUserKey, "assetCount") as number
      const nextAssetCount = prevAssetCount - 1
      cache.writeFragment(
        gql`
          fragment _ on User {
            assetCount
          }
        `,
        { id: prevAssignableId, assetCount: nextAssetCount }
      )
    }
  })
}

export function handleUserAssetCount(userData: UserAssetUpdate, cache: Cache) {
  const userKey = cache.keyOfEntity({ __typename: "User", id: userData.id! })
  const prevAssetCount = cache.resolve(userKey, "assetCount") as number
  const nextAssetCount = userData.assetCount + prevAssetCount

  if (prevAssetCount !== nextAssetCount) {
    cache.writeFragment(
      gql`
        fragment _ on User {
          assetCount
        }
      `,
      userData
    )
  }
}

/**
 * Updates the cache with user data using a GraphQL fragment.
 * @param userData - The user data object.
 * @param cache - The urql cache instance.
 */
export function updateCacheWithUserData(userData: WithTypename<User>, cache: Cache) {
  cache.writeFragment(
    gql`
      fragment _ on User {
        id
        currentProjectId
        currentTaskId
        projectId
        taskId
        firstName
        imageUrl
        isClockedIn
        jobTitle
        lastName
        secondsClockedSinceOrgMidnight
        latestTimeEntry {
          id
          durationInSeconds
          startAt
        }
        currentProject {
          id
          name
        }
        currentTask {
          id
          name
        }
        project {
          id
          name
        }
        task {
          id
          name
        }
      }
    `,
    userData
  )
}

/**
 * Obtains the task id from the cache for a given user id.
 * @param userId The user id to get the task id from
 * @param cache The graphcache object
 * @returns The task id the user is assigned to
 */
export function getTaskIdFromUserId(userId: string, cache: Cache): string | null {
  const userKey = cache.keyOfEntity({ __typename: "User", id: userId })

  return cache.resolve(userKey, "currentTaskId") as string | null
}
