import { clsx } from "clsx"
import { FC, useState } from "react"
import { User } from "../graphql/generated/gql/graphql"
import { getUserInitials } from "../helpers/strings/getUserInitials"
import { colors } from "../helpers/styles/colors"
import { PickPlus } from "../types/helpers"

type MonogramProps = {
  user: PickPlus<User, "imageUrl" | "firstName" | "lastName"> | null | undefined
  className?: string
  height?: string
  width?: string
  textSize?: string
  background?: string
  color?: string
}

export const Monogram: FC<MonogramProps> = ({ user, className, height = "h-10", width = "w-10" }) => {
  const [error, setError] = useState<boolean | undefined>()
  const classes = clsx(
    "rounded-full bg-gray-200 text-gray-800 text-sm",
    user ? "" : "skeleton",
    height,
    width,
    className
  )

  const defaultAvatar = (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <rect fill={colors.gray[100]} />
      <text
        x="50%"
        y="54%"
        alignmentBaseline="middle"
        color={colors.gray[800]}
        dominantBaseline="middle"
        fill={colors.gray[800]}
        fontFamily="sans-serif"
        fontSize="21"
        fontWeight="600"
        textAnchor="middle"
        letterSpacing="0.1em"
      >
        {user && getUserInitials(user)}
      </text>
    </svg>
  )

  if (user?.imageUrl && !error) {
    return (
      <img
        className={classes}
        src={user.imageUrl}
        alt={`${user.firstName} ${user.lastName}`}
        onError={() => setError(true)}
      />
    )
  }

  return defaultAvatar
}

type MonogramRingProps = Omit<MonogramProps, "user"> & {
  user: PickPlus<User, "imageUrl" | "firstName" | "lastName" | "isClockedIn"> | null | undefined
  ringWidth?: string
  ringHeight?: string
  ringBorder?: string
}

export const MonogramRing: FC<MonogramRingProps> = ({
  user,
  ringWidth = "w-10",
  ringHeight = "h-10",
  ringBorder = "border-2",
  width = "w-8",
  height = "h-8",
  ...props
}) => {
  return (
    <div className={clsx(ringWidth, ringHeight, "relative flex justify-center items-center")}>
      <div
        className={clsx(
          ringWidth,
          ringHeight,
          ringBorder,
          "absolute rounded-[50%]",
          user?.isClockedIn ? "border-blue-600" : "border-gray-300"
        )}
      />
      <Monogram user={user} width={width} height={height} {...props} />
    </div>
  )
}
