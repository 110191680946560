import { Box } from "@mui/material"
import { clsx } from "clsx"
import { FC, useContext } from "react"
import { BiHive } from "react-icons/bi"
import { BsFillCircleFill } from "react-icons/bs"
import { Division, Organization } from "../graphql/generated/client-types-and-hooks"
import { DevelopmentFeatureFlagContext } from "../providers/DevelopmentFeatureFlagProvider"
import { PickPlus } from "../types/helpers"
import { OrganizationImage } from "./OrganizationImage"

type Props = {
  division?: PickPlus<Organization | Division, "id" | "imageUrl" | "name" | "isPrimary" | "isCurrentAssignment"> | null
  showDivisionTypeLabel?: boolean
  className?: string
  labelClassName?: string
  showCurrentAssignment?: boolean
  noHover?: boolean
}

export const DivisionTypeIconLabel: FC<{ isPrimary: boolean }> = ({ isPrimary }) => {
  return (
    <div className="flex items-center text-gray-600 text-xs">
      <BiHive />
      <div className="ml-1 truncate">{isPrimary && "Primary"} Division</div>
    </div>
  )
}

export const DivisionBadge: FC<Props> = ({
  division,
  showDivisionTypeLabel = false,
  className,
  labelClassName,
  showCurrentAssignment,
  noHover = false,
}) => {
  const { flagIsEnabled } = useContext(DevelopmentFeatureFlagContext)

  return (
    <div className={clsx("flex gap-2 items-center justify-start pr-1", className)}>
      <OrganizationImage organization={division} className="min-w-[40px] min-h-[40px] size-[40px]" />
      <div className={clsx("grid justify-center content-center gap-y-0.5", labelClassName)}>
        <div
          className={clsx(
            "w-full flex flex-row justify-start items-center leading-tight font-medium md:font-normal truncate",
            noHover ? "" : "cursor-pointer hover:underline underline-offset-1 "
          )}
        >
          {showCurrentAssignment && division?.isCurrentAssignment && (
            <BsFillCircleFill className="text-blue-500 size-[6px] mr-1 inline-block" />
          )}
          <div className="truncate text-left text-gray-800">{division?.name ?? "..."}</div>
        </div>
        {showDivisionTypeLabel && flagIsEnabled("Divisions") && (
          <Box className="flex flex-row gap-2">
            <DivisionTypeIconLabel isPrimary={division?.isPrimary ?? false} />
          </Box>
        )}
      </div>
    </div>
  )
}
