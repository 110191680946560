import { Cache, Entity, Link } from "@urql/exchange-graphcache"
import { MutationReassignUsersArgs, UserFilter } from "../../../graphql/generated/client-types-and-hooks"
import { QueryUsersArgs, User, WithTypename } from "../../../graphql/generated/graphcache"
import { invalidateTaskCache } from "./utils/task.util"
import { UserWithPreviousTaskId, invalidateUsersListCache, updateCacheWithUserData } from "./utils/user.utils"
import { invalidateProjectCache } from "./utils/project.util"

/**
 * Reassigns users to tasks and updates the cache accordingly.
 * @param {Object} result - The result object containing users.
 * @param {MutationReassignUsersArgs} args - The arguments for user reassignment.
 * @param {Cache} cache - The urql cache instance.
 */
export const reassignUsers = (
  result: { reassignUsers: UserWithPreviousTaskId[] },
  args: MutationReassignUsersArgs,
  cache: Cache
) => {
  const userListingQueryNames = { users: true, usersList: true }
  const usersWithTaskAssignments = prepareUserData(result, args)

  invalidateTaskCache(cache)
  invalidateProjectCache(cache)
  invalidateUsersListCache(cache)
  usersWithTaskAssignments.forEach((userData) => {
    updateCacheWithUserData(userData, cache)
    updateUserConnections(userData, cache, userListingQueryNames)
  })
}

/**
 * Prepares user data by combining the results and arguments.
 * @param result - The result object containing users.
 * @param args - The arguments for user reassignment.
 * @returns The array of prepared user data.
 */
function prepareUserData(
  result: { reassignUsers: UserWithPreviousTaskId[] },
  args: MutationReassignUsersArgs
): WithTypename<User>[] {
  return args.assignments.map((assignment) => ({
    ...{
      id: assignment.userId,
      previousTaskId: assignment.previousTaskId,
      currentTaskId: assignment.taskId,
      taskId: assignment.taskId,
      latestTimeEntry: {
        __typename: "TimeEntry",
      },
      __typename: "User",
    },
    ...(result.reassignUsers.find((r) => r.id === assignment.userId) || {}),
  })) as unknown as WithTypename<User>[]
}

/**
 * Updates user connections in the cache based on task assignment.
 * @param userData - The user data object.
 * @param cache - The urql cache instance.
 * @param userQueryNames - A map of query names for user listings.
 */
function updateUserConnections(userData: WithTypename<User>, cache: Cache, userQueryNames: Record<string, boolean>) {
  const userEdge = createUserEdge(userData)
  const userEdgeKey = cache.keyOfEntity(userEdge) as string | null

  const connectionQueries = cache.inspectFields("Query").filter((field) => userQueryNames[field.fieldName])

  connectionQueries.forEach((query) => {
    if (query && query.arguments) {
      const { filter } = query.arguments as QueryUsersArgs

      const fieldKey = cache.resolve("Query", query.fieldKey) as Entity
      const data = cache.resolve(fieldKey, "edges") as Link<Entity>[]
      if (!Array.isArray(data)) return
      if (filter?.taskId || filter?.projectId) {
        updateConnectionBasedOnTaskOrProject(userData, filter, data, userEdge, userEdgeKey, query.fieldKey, cache)
      }
    }
  })
}

/**
 * Creates an edge object for a user.
 * @param userData - The user data object.
 * @returns The user edge object.
 */
function createUserEdge(userData: WithTypename<User>): {
  __typename: string
  node: WithTypename<User>
  cursor: string
} {
  return {
    __typename: "QueryUsersConnectionEdge",
    node: userData,
    cursor: btoa(`GPC:S:${userData.id}`),
  }
}

/**
 * Updates the connection in the cache based on task assignment.
 * @param userData - The user data object.
 * @param taskId - The ID of the task.
 * @param data - The current data in the cache.
 * @param userEdge - The edge object for the user.
 * @param userEdgeKey - The cache key for the user edge.
 * @param fieldKey - The key of the field in the cache.
 * @param cache - The urql cache instance.
 */
function updateConnectionBasedOnTaskOrProject(
  userData: WithTypename<User>,
  filter: UserFilter,
  data: Link<Entity>[],
  userEdge: { __typename: string; node: WithTypename<User>; cursor: string },
  userEdgeKey: string | null,
  fieldKey: string,
  cache: Cache
) {
  if (
    (filter?.taskId && filter?.taskId === userData.taskId) ||
    (filter?.projectId && filter?.projectId === userData.projectId)
  ) {
    // Add the user to the list
    data.push(userEdge)
    cache.link("Query", fieldKey, data)
  } else {
    // Remove the user from the list
    if (data.length > 0) {
      const filteredData = data.filter((cacheKey) => cacheKey !== userEdgeKey)
      cache.link("Query", fieldKey, filteredData)
    } else {
      cache.invalidate("Query", fieldKey)
    }
  }
}
