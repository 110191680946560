import { clsx } from "clsx"
import { FC, ReactNode } from "react"
import { overrideTailwindClasses } from "tailwind-override"

type Props = {
  children: ReactNode
  className?: string
}

/** @deprecated use MUI Typography component with variant="body1" instead */
export const P: FC<Props> = ({ children, className = "", ...props }) => (
  <p {...props} className={overrideTailwindClasses(clsx("mt-2 mb-4 text-gray-800", className))}>
    {children}
  </p>
)
