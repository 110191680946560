import { clsx } from "clsx"
import { FC, ReactNode } from "react"
import { overrideTailwindClasses } from "tailwind-override"

type Props = {
  children: ReactNode
  className?: string
}

export const H2: FC<Props> = ({ children, className = "", ...props }) => (
  <h2
    {...props}
    className={overrideTailwindClasses(clsx("text-3xl lg:text-4xl text-gray-800 font-bold mt-2 mb-4", className))}
  >
    {children}
  </h2>
)
