import styled from "@emotion/styled"
import {
  closeSnackbar,
  enqueueSnackbar,
  MaterialDesignContent,
  OptionsWithExtraProps,
  SnackbarKey,
  VariantType,
} from "notistack"
import { FC } from "react"
import { BiX } from "react-icons/bi"
import { muiTheme } from "../../styles/mui-theme"

const StyledSnackbars = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-default": {
    backgroundColor: muiTheme.palette.black.main,
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: muiTheme.palette.error.main,
  },
  "&.notistack-MuiContent-info": {
    backgroundColor: muiTheme.palette.primary.main,
  },
  "&.notistack-MuiContent-success": {
    backgroundColor: muiTheme.palette.success.main,
  },
  "&.notistack-MuiContent-warning": {
    backgroundColor: muiTheme.palette.warning.main,
  },
}))

export const ThemedSnackbars = {
  default: StyledSnackbars,
  error: StyledSnackbars,
  info: StyledSnackbars,
  success: StyledSnackbars,
  warning: StyledSnackbars,
}

export const SnackbarProviderActions: FC<{ snackbarId: SnackbarKey }> = ({ snackbarId }) => (
  <button className="pr-2" onClick={() => closeSnackbar(snackbarId)}>
    <BiX />
  </button>
)

const handleError = (error: string | Error | unknown) => (error instanceof Error ? error.message : (error as string))

export const errorSnack = (message: string | Error | unknown, options?: OptionsWithExtraProps<VariantType>) =>
  enqueueSnackbar(handleError(message), { ...options, variant: "error" })
export const infoSnack = (message: string, options?: OptionsWithExtraProps<VariantType>) =>
  enqueueSnackbar(message, { ...options, variant: "info" })
export const successSnack = (message: string, options?: OptionsWithExtraProps<VariantType>) =>
  enqueueSnackbar(message, { ...options, variant: "success" })
export const warningSnack = (message: string, options?: OptionsWithExtraProps<VariantType>) =>
  enqueueSnackbar(message, { ...options, variant: "warning" })
