import React, { useState } from "react"
import { BiMobileAlt, BiRightArrowAlt, BiX } from "react-icons/bi"
import { Box, Button, Typography, IconButton } from "@mui/material"
import { ModalProps } from "../../Modals/hooks/useModalProps"

export const DownloadMobileAppNavItem: React.FC<{ downloadMobileAppModalProps: ModalProps }> = ({
  downloadMobileAppModalProps,
}) => {
  const [isVisible, setIsVisible] = useState(() => {
    return localStorage.getItem("mobileAppBannerClosed") !== "true"
  })

  const handleClose = () => {
    setIsVisible(false)
    localStorage.setItem("mobileAppBannerClosed", "true")
  }

  if (!isVisible) return null

  return (
    <Box className="flex flex-col items-center py-4 border rounded-lg text-blue-600 relative">
      <IconButton onClick={handleClose} className="absolute top-0 right-0">
        <BiX />
      </IconButton>
      <BiMobileAlt className="size-7" />
      <Typography variant="h5" className="text-center mt-2 mb-0 text-gray-800">
        CrewView
        <br /> mobile app
      </Typography>
      <Button
        variant="text"
        endIcon={<BiRightArrowAlt />}
        className="text-lg"
        onClick={() => {
          downloadMobileAppModalProps.handleOpenModal()
        }}
      >
        Learn more
      </Button>
    </Box>
  )
}
