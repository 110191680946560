import * as Sentry from "@sentry/nextjs"
import { NextApiRequest, NextApiResponse } from "next"
import { Logger } from "../../lib/logger"

export class ResponseError extends Error {
  public statusCode!: number

  constructor(error: ResponseError | string | Error, statusCode = 500) {
    super()

    if (error instanceof ResponseError) {
      this.stack = error.stack || this.stack // keep the stack of the first error
      this.message = error.message || this.message // keep the message of the first error
      this.statusCode = error.statusCode || statusCode // keep the statusCode of the first error
    } else if (error instanceof Error) {
      this.stack = error.stack || this.stack // keep the stack of the first error
      this.message = error.message || this.message // keep the message of the first error
      this.statusCode = statusCode
    } else {
      {
        this.message = error
        this.statusCode = statusCode
      }
    }
  }
}

export function errorWithStatus(
  error: { statusCode?: number; message?: string },
  req: NextApiRequest,
  res: NextApiResponse
) {
  try {
    const statusCode = error?.statusCode || 500
    const message = error?.message || error

    const info = `${statusCode} message: ${message} ${req.method} ${req.url}`

    // HTTP 5xx are server errors, the server will log these as errors
    if (statusCode >= 500 && statusCode <= 599) {
      Sentry.captureException(error)
      Logger.error("error -", info)
    }
    // HTTP 4xx are client errors, the server will log these as warnings
    else if (statusCode >= 400 && statusCode <= 499) {
      Logger.warn("warn -", info)
    }
    // all other codes will be logged as INFO
    else Logger.info("info -", info)

    return res.status(statusCode).send(message)
  } catch (err) {
    Logger.error("errorWithStatus", err)
    Sentry.captureException(err)
    return res.status(500).send(err)
  }
}
