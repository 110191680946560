import { NextComponentType, NextPageContext } from "next"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { FC, useContext } from "react"
import { LoadingIndicator } from "../components/Loading/LoadingIndicator"
import { BreadcrumbsProvider } from "../providers/BreadcrumbsProvider"
import { DrawerProvider } from "../components/Partials/Drawer/providers/DrawerProvider"
import { OrganizationSettingsProvider } from "../providers/OrganizationSettingsProvider"
import { PermissionsProvider } from "../providers/PermissionsProvider/PermissionsProvider"
import { CurrentUserProvider } from "../providers/PermissionsProvider/currentUserProvider"
import { SessionContext } from "../providers/SessionProvider"
import qs from "qs"
import { MobileAppDownloadBanner } from "../components/MobileAppDownloadBanner"

// We don't need this as part of the server rendered page
const PageLoadingProgress = dynamic(
  () => import("../components/PageLoadingProgress").then((mod) => mod.PageLoadingProgress),
  {
    ssr: false,
  }
)

const publicUrls = [
  "/404",
  "/auth/email-login",
  "/auth/login",
  "/auth/reset-password",
  "/auth/verify",
  "/auth/error",
  "/unsubscribe",
] as string[]

export type PageProps = Record<string, string>

type Props = {
  Component: NextComponentType<NextPageContext, unknown, PageProps>
  pageProps: PageProps
}

const Router: FC<Props> = ({ Component, pageProps }) => {
  const router = useRouter()
  const { status } = useContext(SessionContext)

  if (status === "unauthenticated") {
    if (publicUrls.includes(router.pathname)) {
      return <Component {...pageProps} />
    }

    const path = window.location.pathname

    const loginPath = path === "/" ? "/auth/login" : `/auth/login/?${qs.stringify({ return_to: path })}`

    router.push(loginPath)
  } else if (status === "loading") {
    return (
      <div className="flex min-h-screen w-screen items-center justify-center">
        <LoadingIndicator />
      </div>
    )
  }

  return (
    <>
      <CurrentUserProvider>
        <PermissionsProvider>
          <OrganizationSettingsProvider>
            <BreadcrumbsProvider>
              <PageLoadingProgress />
              <DrawerProvider>
                <Component {...pageProps} />
              </DrawerProvider>
              <MobileAppDownloadBanner />
            </BreadcrumbsProvider>
          </OrganizationSettingsProvider>
        </PermissionsProvider>
      </CurrentUserProvider>
    </>
  )
}

export default Router
