import { Cache, Data, Entity, FieldInfo, Link } from "@urql/exchange-graphcache"
import { gql } from "urql"
import { UserAssignment } from "../../graphql/generated/graphcache"

export const updateImageCache = async (url: string) => {
  try {
    await fetch(url, { cache: "reload" })
    document.body.querySelectorAll(`img[src='${url}']`).forEach((img) => ((img as HTMLImageElement).src = url))
  } catch (e) {
    // In some cases, this fetch might fail but since this is a "best effort" sort of thing, we are comfortable handling/ignoring the error
    console.error(e)
  }
}

const orgClockedInCountUpdateDocument = gql`
  query OrgClockedInCountUpdateDocument {
    myOrganization {
      id
      clockedInUserCount
    }
  }
`

export const updateOrganizationClockedInCount = (cache: Cache, countChange: number) => {
  cache.updateQuery({ query: orgClockedInCountUpdateDocument }, (data) => {
    if (data?.myOrganization) {
      data.myOrganization.clockedInUserCount += countChange
    }
    return data
  })
}

export const linkUserAssignment = (
  cache: Cache,
  newUserAssignment: UserAssignment,
  userKey: string | null,
  projectKey: string | null
) => {
  const userAssignments = cache.resolve(userKey, "assignments") as UserAssignment[] | null
  const projectAssignments = cache.resolve(projectKey, "assignees") as UserAssignment[] | null

  // If we don't have it, it means the query hasn't yet been populated and it doesn't need to be updated
  if (Array.isArray(userAssignments)) {
    // Add the result
    userAssignments.push(newUserAssignment)
    cache.link(userKey, "assignments", userAssignments as Link<Entity>)
  }
  // If we don't have it, it means the query hasn't yet been populated and it doesn't need to be updated
  if (Array.isArray(projectAssignments)) {
    // Add the result
    projectAssignments.push(newUserAssignment)
    cache.link(projectKey, "assignees", projectAssignments as Link<Entity>)
  }
}

export const removeFromQuery = (cache: Cache, query: FieldInfo, key: string | null) => {
  const data = cache.resolve("Query", query.fieldKey)

  if (data && Array.isArray(data)) {
    const newList = data.filter((k) => k !== key) as Link<Entity>

    cache.link("Query", query.fieldKey, newList)
  } else {
    invalidateQuery(cache, query)
  }
}

export const addToQuery = (cache: Cache, query: FieldInfo, newData: Data) => {
  const data = cache.resolve("Query", query.fieldKey)

  if (data && Array.isArray(data)) {
    const newList = data.slice()
    newList.push(newData)
    cache.link("Query", query.fieldKey, newList as Link<Entity>)
  }
}

export const removeManyFromQuery = (
  cache: Cache,
  query: FieldInfo,
  keys: (string | null)[],
  keyMap?: Record<string, boolean>
) => {
  const data = cache.resolve("Query", query.fieldKey)

  if (data && Array.isArray(data)) {
    const filteredData = keyMap ? data.filter((k) => !keyMap[k]) : data.filter((k) => !keys.includes(k))

    if (!filteredData.length) {
      cache.invalidate("Query", query.fieldKey)
    } else {
      cache.link("Query", query.fieldKey, filteredData as Link<Entity>)
    }
  }
}

export const addManyToQuery = (cache: Cache, query: FieldInfo, newData: Data[]) => {
  const data = cache.resolve("Query", query.fieldKey)

  if (data && Array.isArray(data)) {
    data.push(...newData)
    cache.link("Query", query.fieldKey, data as Link<Entity>)
  }
}

export const invalidateQuery = (cache: Cache, query: FieldInfo) => {
  cache.invalidate("Query", query.fieldKey)
  cache.invalidate("Query", query.fieldName, query.arguments)
}

export const nullToUndefined = (obj: Record<string, unknown>) =>
  Object.keys(obj).reduce(
    (acc, key) => {
      acc[key] = obj[key] != null ? obj[key] : undefined
      return acc
    },
    {} as Record<string, unknown>
  )
