import { FC, ReactElement, ReactNode, useContext } from "react"
import { DecisionContext, PermissionsContext } from "../providers/PermissionsProvider/PermissionsProvider"
import { CheckedPermission } from "../types/Permission"

type Props = {
  children: ReactNode
  permissionsInclude: CheckedPermission | CheckedPermission[]
  fallbackComponent?: ReactElement
  context?: DecisionContext
}

export const RenderIf: FC<Props> = ({ permissionsInclude, fallbackComponent = null, children, context }) => {
  const { hasPermissionTo } = useContext(PermissionsContext)

  if (hasPermissionTo(permissionsInclude, context)) {
    return <>{children}</>
  }

  return fallbackComponent
}
