import { ButtonProps, IconButtonProps } from "@mui/material"
import { FC, ReactNode } from "react"
import { MenuItem, QuickMenuMui } from "./QuickMenuMui"

type Props = {
  children: ReactNode
  items: MenuItem[][]
  className?: string
  menuButtonClassName?: string
  menuButtonProps?: ButtonProps | IconButtonProps
  menuClassName?: string
  buttonShape?: "default" | "round"
  disabled?: boolean
}

/**
 * @deprecated Use QuickMenuMui instead with relevant variants defined in the theme.
 */
export const QuickMenu: FC<Props> = ({
  items,
  className,
  children,
  menuButtonClassName,
  menuButtonProps,
  menuClassName,
  buttonShape = "default",
  disabled,
}) => {
  return (
    <div className={className}>
      <QuickMenuMui
        className={menuButtonClassName}
        menuClassName={menuClassName}
        items={items}
        buttonProps={menuButtonProps}
        buttonShape={buttonShape}
        disabled={disabled}
      >
        {children}
      </QuickMenuMui>
    </div>
  )
}
