import { Button, ButtonProps } from "@mui/material"
import { FC } from "react"

/**
 * @deprecated Use MUI Button with variant="contained" instead
 */
export const ButtonFilled: FC<Omit<ButtonProps, "variant"> & { className?: string; component?: string }> = ({
  children,
  className = "",
  disabled = false,
  type,
  ...props
}) => {
  return (
    <Button variant="contained" {...props} type={type} disabled={disabled} className={className}>
      {children}
    </Button>
  )
}
