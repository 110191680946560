import { Cache } from "@urql/exchange-graphcache"
import { AssetRepairRequestsQueryDocument } from "../../../../graphql/generated/client-types-and-hooks"
import { invalidateQuery } from "../../cacheHelpers"

type RepairArgs = {
  report: {
    assetRepairId: string
  }
}

export const handleAssetRepairReport = <T extends RepairArgs>(_: unknown, args: T, cache: Cache) => {
  const report = args.report
  // Update asset repair requests
  cache.updateQuery(
    { query: AssetRepairRequestsQueryDocument, variables: { assetRepairId: report.assetRepairId } },
    (data) => {
      return data
    }
  )
  // Update asset in asset list
  const fieldsToUpdate: Record<string, boolean> = {
    assets_2: true,
    assets: true,
    asset: true,
  }
  cache
    .inspectFields("Query")
    .filter((info) => fieldsToUpdate[info.fieldName])
    .forEach((fieldInfo) => {
      invalidateQuery(cache, fieldInfo)
    })
}
