import { clsx } from "clsx"
import { FC, ReactNode } from "react"

const testLabel_GenericTableRow = "table-row"

type Props = {
  children: ReactNode
  className?: string
  includeSeparator?: boolean
  isCardOnMobile?: boolean
  testLabel?: string
}

export const Row: FC<Props> = ({
  className = "",
  children,
  includeSeparator = true,
  isCardOnMobile = true,
  testLabel = testLabel_GenericTableRow,
}) => {
  return (
    <div
      className={clsx(
        "md:rounded-none md:border-0 md:filter-none md:px-0 md:py-4 md:hover:bg-gray-25 leading-none",
        isCardOnMobile && "rounded-lg border border-gray-200 bg-white p-4",
        !isCardOnMobile && "first-of-type:border-t first-of-type:border-gray-200",
        includeSeparator && "md:border-b",
        !isCardOnMobile && includeSeparator && "border-b md:border-b last-of-type:border-b-0 md:last-of-type:border-b",
        className
      )}
      test-label={testLabel}
    >
      {children}
    </div>
  )
}
