import { SwipeableDrawer } from "@mui/material"
import { clsx } from "clsx"
import { createContext, FC, useContext, useEffect, useMemo, useState } from "react"
import { useIsIos } from "../../../../../hooks/useIsIos"
import { useWindowSize } from "../../../../../hooks/useWindowSize"
import { muiTheme } from "../../../../../styles/mui-theme"
import { DrawerContext } from "../../providers/DrawerProvider"
import { warnOnExitStoreActions } from "../../../../../stores/warnOnExit"

type DrawerProps = {
  children: React.ReactNode
  level: number
}

type ContextValue = {
  handleClose: () => void
  level: number
}

export const SingleDrawerContext = createContext<ContextValue>({} as ContextValue)

export const Drawer: FC<DrawerProps> = ({ children, level }) => {
  const [isOpen, setIsOpen] = useState(false)
  const drawerContext = useContext(DrawerContext)
  const { width: browserWidth } = useWindowSize()
  const isMobile = useMemo(() => (browserWidth || 0) < 768, [browserWidth])
  const isIos = useIsIos()

  const { confirmExit } = warnOnExitStoreActions

  // This is prevents the drawer from opening on mount and not getting the nice animation
  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true)
    }, 10)
  }, [])

  const handleClose = () => {
    confirmExit(() => {
      setIsOpen(false)

      setTimeout(() => {
        drawerContext.pop()
      }, 200)
    })
  }

  return (
    <>
      <SwipeableDrawer
        anchor={isMobile ? "bottom" : "right"}
        disableBackdropTransition={isMobile}
        disableDiscovery={isIos}
        onClose={handleClose}
        onOpen={() => {}}
        open={isOpen}
        PaperProps={{
          classes: {
            root: clsx(`rounded-t-xl`, "md:top-0 md:rounded-t-none", `lg:w-[90%]`),
          },
          sx: {
            [muiTheme.breakpoints.down("lg")]: {
              top: `${(level + 1) * 10}px`,
            },
            [muiTheme.breakpoints.up("lg")]: {
              maxWidth: `${1000 - level * 10}px`,
            },
          },
        }}
      >
        <SingleDrawerContext.Provider
          value={{
            handleClose,
            level,
          }}
        >
          {children}
        </SingleDrawerContext.Provider>
      </SwipeableDrawer>
    </>
  )
}
