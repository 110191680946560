import {
  BiAbacus,
  BiBarChartSquare,
  BiBuildings,
  BiCar,
  BiGroup,
  BiHomeAlt,
  BiLayer,
  BiStoreAlt,
  BiTransferAlt,
} from "react-icons/bi"
import { UserNotificationModelType } from "../../../../graphql/generated/client-types-and-hooks"
import { NavItemData } from "../NavItem"

export const navigation: NavItemData[] = [
  {
    name: "Home",
    icon: BiHomeAlt,
    href: "/",
  },
  {
    name: "Projects",
    icon: BiLayer,
    href: "/projects",
    requiredPermission: "project:list",
  },
  {
    name: "Team",
    icon: BiGroup,
    href: "/team",
    requiredPermission: "user:list",
    getApplicableNotificationsCount: (notifications) =>
      notifications.filter((n) => n.modelType === UserNotificationModelType.User).length,
  },
  {
    name: "Assets",
    icon: BiCar,
    href: "/assets",
    requiredPermission: "asset:list",
    getApplicableNotificationsCount: (notifications) =>
      notifications.filter((n) => n.modelType === UserNotificationModelType.Asset).length,
  },
  {
    name: "Businesses",
    icon: BiBuildings,
    href: "/businesses",
    requiredPermission: "customer:list",
    requiredDevelopmentFeatureFlag: "Customers",
  },
  {
    name: "Dispatch",
    icon: BiTransferAlt,
    href: "/dispatch",
    requiredDevelopmentFeatureFlag: "Dispatch",
  },
  {
    name: "Reports",
    icon: BiBarChartSquare,
    href: "/reports",
    requiredPermission: "timeEntry:export",
  },
  {
    name: "Kitchen Sink",
    icon: BiAbacus,
    href: "/kitchen-sink",
    requiredDevelopmentFeatureFlag: "Kitchen Sink",
    bottom: true,
  },
  {
    name: "Organizations",
    icon: BiStoreAlt,
    href: "/admin/organizations",
    requiredPermission: "blackthornEmployee:*",
    bottom: true,
  },
  // {
  //   name: 'Time',
  //   icon: BiTime,
  //   href: '/time',
  // },
  // {
  //   name: "Equipment",
  //   icon: BiWrench,
  //   href: "/equipment",
  // },
]
