import { useStore } from "@tanstack/react-store"
import { FC } from "react"
import { warnOnExitStore, warnOnExitStoreActions } from "../../stores/warnOnExit"
import { ModalBody } from "./components/Elements/ModalBody"
import { MuiModal } from "./components/Elements/MuiModal"
import { ModalProps } from "./hooks/useModalProps"

export const ConfirmCancelModal: FC<{ modalProps: ModalProps }> = ({ modalProps }) => {
  const { onConfirmExit } = useStore(warnOnExitStore)
  const actions = warnOnExitStoreActions

  const onLeave = (_e?: object, reason?: string) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown" || reason === "xButtonClick") {
      modalProps.handleCloseModal()
      actions.hideExitModal()

      return
    }

    onConfirmExit?.()
    actions.clear()
  }

  const onStay = () => {
    actions.hideExitModal()
    modalProps.handleCloseModal()
  }

  return (
    <MuiModal
      contentLabel="Unsaved Data"
      submitButtonText="Stay"
      cancelButtonText="Leave"
      cancelButtonVariant="contained"
      cancelButtonColor="error"
      variant="small"
      isOpen={modalProps.isOpen}
      handleCloseModal={onLeave}
      submitForm={onStay}
    >
      <ModalBody>
        <p>
          {`You have unsaved data. Are you sure you want to leave the current workflow? Click 'Stay' to continue working
          or 'Leave' to exit without saving.`}
        </p>
      </ModalBody>
    </MuiModal>
  )
}
