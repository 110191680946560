import { GrantedPermission, PermissionRoleNameMap } from "../types/Permission"
import rolePermissions from "./roleDefinitions.json"

function roleNameSetGenerator(): Set<GrantedPermission> {
  const roleNameSet = new Set()

  for (const roleArray of Object.values(rolePermissions as PermissionRoleNameMap)) {
    Object.values(roleArray).map((roleString) => roleNameSet.add(roleString))
  }
  return roleNameSet as Set<GrantedPermission>
}

export const RoleNameSet = roleNameSetGenerator()

export default rolePermissions as PermissionRoleNameMap
