import { FC } from "react"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { DivisionBadge } from "../../DivisionBadge"

type Props = {
  className?: string
  labelClassName?: string
}

const LogoQueryDocument = graphql(`
  query LogoQuery {
    myDivision {
      id
      name
      imageUrl
      isPrimary
      isCurrentAssignment
      divisionCount
    }
  }
`)

const Logo: FC<Props> = ({ className, labelClassName }) => {
  const [{ data }] = useQuery({ query: LogoQueryDocument, requestPolicy: "cache-first" })
  const showTypeLabel = (data?.myDivision?.divisionCount ?? 0) > 0

  return (
    <DivisionBadge
      division={data?.myDivision}
      showDivisionTypeLabel={showTypeLabel}
      className={className}
      labelClassName={labelClassName}
      noHover
    />
  )
}

export default Logo
