import { AssetStatus } from "../../graphql/generated/client-types-and-hooks"

export const { Available, BeingRepaired, InUse, Missing, NeedsRepair, OutOfService, Sold, Transferred } = AssetStatus

type AssetStatusesMappedItem = { label: string; canBeActive: boolean; canBeArchived: boolean; isOperational: boolean }

const assetStatusMapping: { [key in AssetStatus]: AssetStatusesMappedItem } = {
  [Available]: { label: "Available", canBeActive: true, canBeArchived: false, isOperational: true },
  [BeingRepaired]: { label: "Being Repaired", canBeActive: true, canBeArchived: false, isOperational: false },
  [InUse]: { label: "In Use", canBeActive: true, canBeArchived: false, isOperational: true },
  [Missing]: { label: "Missing", canBeActive: true, canBeArchived: true, isOperational: false },
  [NeedsRepair]: { label: "Needs Repair", canBeActive: true, canBeArchived: false, isOperational: false },
  [OutOfService]: { label: "Out of Service", canBeActive: true, canBeArchived: true, isOperational: false },
  [Sold]: { label: "Sold", canBeActive: false, canBeArchived: true, isOperational: false },
  [Transferred]: { label: "Transferred", canBeActive: false, canBeArchived: true, isOperational: false },
}

export const isOperational = (status: AssetStatus) => {
  if (status in AssetStatus) return assetStatusMapping[status].isOperational
  return true
}

type AssetStatusOption = {
  value: AssetStatus
  label: AssetStatusesMappedItem["label"]
}

export const getAssetStatusLabel = (status: AssetStatus) => {
  return assetStatusMapping[status]?.label
}

export const getAssetStatusOption = (status: AssetStatus): AssetStatusOption => {
  const defaultStatus = Available
  if (status in AssetStatus) {
    return {
      value: AssetStatus[status],
      label: getAssetStatusLabel(status),
    }
  }
  return {
    value: defaultStatus,
    label: getAssetStatusLabel(status),
  }
}

export const stringToAssetStatus = (status?: string): AssetStatus => {
  const defaultStatus = Available
  if (!status) return defaultStatus
  if (status in AssetStatus) return AssetStatus[status as keyof typeof AssetStatus]
  return defaultStatus
}

export const assetStatusList = Object.keys(AssetStatus)
  .reduce(
    (acc, status) => {
      if (isNaN(Number(status))) {
        const statusMap = assetStatusMapping[status as AssetStatus]
        acc.push({
          value: status as AssetStatus,
          label: statusMap.label,
          canBeActive: statusMap.canBeActive,
          canBeArchived: statusMap.canBeArchived,
          isOperational: statusMap.isOperational,
        })
      }
      return acc
    },
    [] as (AssetStatusesMappedItem & { value: AssetStatus })[]
  )
  .sort((a, b) => {
    if (assetStatusMapping[a.value].isOperational) return -1
    if (assetStatusMapping[b.value].isOperational) return 1
    return 0
  })

export const assetStatusOptions: AssetStatusOption[] = assetStatusList.map((status) =>
  getAssetStatusOption(status.value)
)
