import { Button, ButtonProps, IconButton, IconButtonProps, Menu, MenuItem } from "@mui/material"
import { clsx } from "clsx"
import Link from "next/link"
import { AnchorHTMLAttributes, FC, ReactElement, ReactNode, useContext, useState } from "react"
import { DevelopmentFeatureFlagName } from "../helpers/api/developmentFeatureFlags"
import { DevelopmentFeatureFlagContext } from "../providers/DevelopmentFeatureFlagProvider"
import { DecisionContext, PermissionsContext } from "../providers/PermissionsProvider/PermissionsProvider"
import { CheckedPermission } from "../types/Permission"
import { TailwindIcon } from "../types/tailwind"
import { DrawerLink } from "./Partials/Drawer/components/Elements/DrawerLink"
import { DrawerName } from "./Partials/Drawer/providers/DrawerProvider"

export const testLabel_QuickMenuActionsButton = "quick-menu-actions-button"
export const testLabel_QuickMenuActionsMenuSection = "quick-menu-actions-menu-section"
const testLabel_QuickMenuActionsMenuItem = "quick-menu-actions-menu-item"

type Colors = "red" | "green" | "orange"

export type MenuItem = {
  value: ReactNode
  onClick?: () => void
  color?: Colors
  Icon?: TailwindIcon
  className?: string
  iconStyles?: string
  isDisabled?: boolean
  disableTouchRipple?: boolean
  requiredPermission?: CheckedPermission
  requiredPermissionContext?: DecisionContext
  requiredFeatureFlag?: DevelopmentFeatureFlagName
  drawer?: { href: string; component: ReactElement; confirmOnClose?: boolean; name: DrawerName }
  href?: string
  target?: AnchorHTMLAttributes<HTMLAnchorElement>["target"]
}

type Props = {
  children: ReactNode
  className?: string
  menuClassName?: string
  items: MenuItem[][]
  buttonProps?: ButtonProps | IconButtonProps
  buttonShape?: "default" | "round"
  disabled?: boolean
  closeOnClick?: boolean
  isFullWidth?: boolean
}

const menuItemFormatting = "rounded whitespace-no-wrap flex items-center hover:bg-gray-100"

export const QuickMenuMui: FC<Props> = ({
  className,
  menuClassName,
  items,
  children,
  buttonProps,
  buttonShape = "default",
  disabled,
  closeOnClick = true,
  isFullWidth = false,
}) => {
  const { hasPermissionTo } = useContext(PermissionsContext)
  const { flagIsEnabled } = useContext(DevelopmentFeatureFlagContext)

  const [anchorEl, setAnchorElement] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => setAnchorElement(e.currentTarget)
  const handleClose = () => setAnchorElement(null)

  if (items.flat().some((i) => i.requiredPermissionContext && !i.requiredPermission)) {
    // if requiredPermissionContext is present, requiredPermission must be passed, too.
    console.error("requiredPermissionContext must be used with requiredPermission")
    return null
  }

  return items.flat().length > 0 ? (
    <>
      <div className={clsx(isFullWidth ? "w-full" : "flex justify-end")}>
        {buttonShape === "round" ? (
          <IconButton
            id="basic-button"
            className={className}
            test-label={testLabel_QuickMenuActionsButton}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={!disabled ? handleClick : undefined}
            color="inherit"
            disabled={disabled}
            {...(buttonProps as IconButtonProps)}
          >
            {children}
          </IconButton>
        ) : (
          <Button
            id="basic-button"
            data-testid="company-name"
            className={className}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={!disabled ? handleClick : undefined}
            color="inherit"
            disabled={disabled}
            {...(buttonProps as ButtonProps)}
          >
            {children}
          </Button>
        )}
        {!disabled && (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{ padding: 0 }}
            className={menuClassName}
          >
            {items
              .filter((section) => section.length)
              .map((section, s) => (
                <div
                  key={`section-${s}`}
                  className={clsx(
                    "border-b border-gray-200 last:border-b-0 px-2",
                    items.length > 1 && "py-2 first:pt-0 last:pb-0"
                  )}
                  style={{ minWidth: "240px" }}
                  test-label={testLabel_QuickMenuActionsMenuSection}
                >
                  {section
                    .filter((item) =>
                      item.requiredPermission || item.requiredPermissionContext
                        ? hasPermissionTo(item.requiredPermission, item.requiredPermissionContext)
                        : true
                    )
                    .filter((item) => (item.requiredFeatureFlag ? flagIsEnabled(item.requiredFeatureFlag) : true))
                    .map(
                      (
                        {
                          color,
                          onClick,
                          isDisabled: isDisabledItem,
                          disableTouchRipple,
                          value,
                          Icon,
                          className: menuItemClassName,
                          iconStyles = "size-5",
                          drawer,
                          href,
                          target,
                        },
                        i
                      ) => {
                        const iconClassName = clsx("mr-2.5", iconStyles)
                        if (drawer)
                          return (
                            <MenuItem
                              key={i}
                              className={clsx(menuItemFormatting, menuItemClassName ?? "p-0")}
                              test-label={testLabel_QuickMenuActionsMenuItem}
                              disabled={isDisabledItem}
                              disableTouchRipple={disableTouchRipple}
                            >
                              <DrawerLink
                                confirmOnClose={drawer?.confirmOnClose}
                                className="px-4 py-3 w-full"
                                href={drawer.href}
                                component={drawer.component}
                                drawerName={drawer.name}
                              >
                                <div className="flex flex-row" onClick={handleClose}>
                                  {Icon && <Icon className={iconClassName} />}
                                  {value}
                                </div>
                              </DrawerLink>
                            </MenuItem>
                          )

                        if (href)
                          return (
                            <Link key={i} href={href} target={target}>
                              <MenuItem
                                className={clsx(
                                  menuItemFormatting,
                                  menuItemClassName ?? "px-4 py-3",
                                  color === "red" && "text-red-600",
                                  color === "green" && "text-green-600",
                                  color === "orange" && "text-orange-600"
                                )}
                                test-label={testLabel_QuickMenuActionsMenuItem}
                                disabled={isDisabledItem}
                                disableTouchRipple={disableTouchRipple}
                              >
                                {Icon && <Icon className={iconClassName} />}
                                {value}
                              </MenuItem>
                            </Link>
                          )

                        return (
                          <MenuItem
                            key={i}
                            className={clsx(
                              menuItemFormatting,
                              menuItemClassName ?? "px-4 py-3",
                              color === "red" && "text-red-600",
                              color === "green" && "text-green-600",
                              color === "orange" && "text-orange-600"
                            )}
                            test-label={testLabel_QuickMenuActionsMenuItem}
                            data-testid={value}
                            disabled={isDisabledItem}
                            disableTouchRipple={disableTouchRipple}
                            onClick={(e) => {
                              e.stopPropagation()
                              onClick?.()
                              closeOnClick && handleClose()
                            }}
                          >
                            {Icon && <Icon className={iconClassName} />}
                            {value}
                          </MenuItem>
                        )
                      }
                    )}
                </div>
              ))}
          </Menu>
        )}
      </div>
    </>
  ) : null
}
