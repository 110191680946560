import { createContext, FC, ReactNode, useCallback, useMemo } from "react"
import { useQuery } from "urql"
import { graphql } from "../graphql/generated/gql"

type UnitOfMeasure = {
  name: string
  symbol: string
}

type ContextValue = {
  requireTimeEntryEvidence: boolean
  requireInjuryReportOnClockout: boolean
  allowTeamMembersClockInOut: boolean
  unitsOfMeasure: UnitOfMeasure[]
  getUnitSymbol: (name: string) => string
}

const OrgSettingsProviderDocument = graphql(`
  query OrgSettingsProvider {
    myOrganization {
      id
      requireTimeEntryEvidence
      requireInjuryReportOnClockout
      allowTeamMembersClockInOut
      unitsOfMeasure {
        id
        name
        symbol
      }
    }
  }
`)

export const OrganizationSettingsContext = createContext<ContextValue>({
  requireTimeEntryEvidence: false,
  requireInjuryReportOnClockout: false,
  allowTeamMembersClockInOut: false,
  unitsOfMeasure: [],
  getUnitSymbol: (_name: string) => "",
})

export const OrganizationSettingsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [{ data }] = useQuery({ query: OrgSettingsProviderDocument, requestPolicy: "cache-first" })

  const unitsOfMeasure = useMemo(() => data?.myOrganization.unitsOfMeasure || [], [data])

  const getUnitSymbol = useCallback(
    (name: string) => {
      const unitOfMeasure = unitsOfMeasure.find((uom) => uom.name === name)
      return unitOfMeasure?.symbol || name
    },
    [unitsOfMeasure]
  )

  return (
    <OrganizationSettingsContext.Provider
      value={{
        requireTimeEntryEvidence: !!data?.myOrganization.requireTimeEntryEvidence,
        requireInjuryReportOnClockout: !!data?.myOrganization.requireInjuryReportOnClockout,
        allowTeamMembersClockInOut: !!data?.myOrganization.allowTeamMembersClockInOut,
        unitsOfMeasure,
        getUnitSymbol,
      }}
    >
      {children}
    </OrganizationSettingsContext.Provider>
  )
}
