import { IconButton } from "@mui/material"
import { clsx } from "clsx"
import Link from "next/link"
import { FC, useContext } from "react"
import { BiArrowBack, BiLinkAlt, BiX } from "react-icons/bi"
import { CgArrowsExpandRight } from "react-icons/cg"
import { warnOnExitStoreActions } from "../../../../../stores/warnOnExit"
import { CopyToClipboard } from "../../../../CopyToClipboard"
import { infoSnack } from "../../../../Notistack/ThemedSnackbars"
import { DrawerContext } from "../../providers/DrawerProvider"
import { SingleDrawerContext } from "./Drawer"

type DrawerHeaderProps = {
  href?: string
}

export const DrawerHeader: FC<DrawerHeaderProps> = ({ href }: DrawerHeaderProps) => {
  const { handleClose } = useContext(SingleDrawerContext)
  const { clearAll, stack } = useContext(DrawerContext)
  const { confirmExit } = warnOnExitStoreActions

  const onClose = () => {
    confirmExit(() => {
      clearAll()
    })
  }

  return (
    <div className={clsx("flex items-center justify-center py-1 px-3", "md:border-b md:justify-between")}>
      <IconButton size="large" className="hidden md:block" onClick={handleClose}>
        <BiArrowBack className={clsx("size-5", (stack?.length || 0) > 1 ? "text-gray-800" : "text-gray-400")} />
      </IconButton>

      <div className={clsx("flex gap-1 items-start", "md:justify-end md:py-3 md:px-6")}>
        {href && (
          <>
            <CopyToClipboard
              className={clsx(
                "hidden size-12 -mr-4 md:mr-0 items-center justify-center flex-none rounded-full transition-colors p-2",
                "md:size-12 md:flex",
                "hover:bg-gray-50"
              )}
              onCopy={() => infoSnack("Copied link to clipboard")}
              text={href}
              title="Copy link to clipboard"
            >
              <BiLinkAlt className="size-6" />
            </CopyToClipboard>
            <Link
              href={href}
              className={clsx(
                "hidden size-12 -mr-4 md:mr-0 items-center justify-center flex-none rounded-full transition-colors p-2",
                "md:size-12 md:flex",
                "hover:bg-gray-50"
              )}
              title="Open in full view"
            >
              <CgArrowsExpandRight className="size-5" />
            </Link>
          </>
        )}
        <IconButton size="large" onClick={onClose} className="hidden md:block" title="Close drawer">
          <BiX className={clsx("size-7", "md:size-6")} />
        </IconButton>
        <div className={clsx("w-full flex justify-center pt-3", "md:hidden")}>
          <div className="h-1.5 w-12 bg-gray-200 rounded-full" />
        </div>
      </div>
    </div>
  )
}
