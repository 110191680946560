import { SwipeableDrawer } from "@mui/material"
import { JsonValue } from "@prisma/client/runtime/library"
import { clsx } from "clsx"
import { FC, useEffect, useState } from "react"
import { BiX } from "react-icons/bi"
import { useQuery } from "urql"
import { WidgetConfigGroup } from "../../components/Widgets/availableWidgets"
import { H4, H5 } from "../../components/deprecated"
import { Role } from "../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../graphql/generated/gql"
import { permissionGroups } from "../../pages/organization/roles"
import { GrantedPermission } from "../../types/Permission"
import { PickPlus } from "../../types/helpers"

type RoleExpectation = PickPlus<Role, "id" | "name" | "widgets"> & {
  permissions: GrantedPermission[] | string[] | JsonValue
}

type Props = {
  currentUserPermissions: GrantedPermission[]
  setCurrentUserPermissions: (permissions: GrantedPermission[]) => void
  roles: RoleExpectation[]
  setEffectiveWidgets: (widgets: WidgetConfigGroup[]) => void
}

export const PERMISSIONS_EXPLORER_LOCALSTORAGE_KEY = "dev_permissions"

const getInitialPermissionState = () => {
  const permissions = sessionStorage.getItem(PERMISSIONS_EXPLORER_LOCALSTORAGE_KEY)

  if (permissions) {
    return JSON.parse(permissions)
  }
}

const PermissionsExplorerQueryDocument = graphql(`
  query PermissionsExplorerGetOrganization {
    myOrganization {
      id
      roles {
        id
        name
        permissions
        widgets
      }
    }
  }
`)

export const PermissionsExplorer: FC<Props> = ({
  currentUserPermissions,
  setCurrentUserPermissions,
  roles,
  setEffectiveWidgets,
}) => {
  const [{ data }] = useQuery({ query: PermissionsExplorerQueryDocument, requestPolicy: "cache-first" })

  // Control the Permission Explorer
  const [permissionExplorerIsOpen, setPermissionExplorerIsOpen] = useState(false)
  const [selectedRoleName, setSelectedRoleName] = useState(roles[0]?.name)
  const handleKeypress = (event: KeyboardEvent) => {
    if (event.repeat) return

    //Keypress to toggle the permission explorer is Shift + Ctrl + Cmd + p
    const isRightKeyCombination = event.shiftKey && event.ctrlKey && event.metaKey && event.key === "P"

    if (isRightKeyCombination) setPermissionExplorerIsOpen((previous) => !previous)
  }
  const initialLocalStorageState = getInitialPermissionState()

  useEffect(() => {
    if (initialLocalStorageState) {
      setCurrentUserPermissions(initialLocalStorageState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", handleKeypress)
    return () => document.removeEventListener("keydown", handleKeypress)
  })

  const setRole = (role: RoleExpectation) => {
    setSelectedRoleName(role.name)
    setCurrentUserPermissions(role.permissions as GrantedPermission[])
    setEffectiveWidgets(role.widgets)
    sessionStorage.setItem(PERMISSIONS_EXPLORER_LOCALSTORAGE_KEY, JSON.stringify(role.permissions))
  }

  const togglePermission = (permission: GrantedPermission) => {
    const updatedPermissions = currentUserPermissions.includes(permission)
      ? currentUserPermissions.filter((p) => p !== permission)
      : [...currentUserPermissions, permission]

    setCurrentUserPermissions(updatedPermissions)
    sessionStorage.setItem(PERMISSIONS_EXPLORER_LOCALSTORAGE_KEY, JSON.stringify(updatedPermissions))
  }

  return (
    <SwipeableDrawer
      onOpen={() => setPermissionExplorerIsOpen(true)}
      onClose={() => setPermissionExplorerIsOpen(false)}
      open={permissionExplorerIsOpen}
      anchor="right"
      disableBackdropTransition
      disableDiscovery
      PaperProps={{
        classes: {
          root: "w-full max-w-lg",
        },
      }}
    >
      <div className="flex items-start justify-between p-8">
        <header className="font-bold text-3xl">Permissions Explorer</header>
        <button className="lg:hidden" onClick={() => setPermissionExplorerIsOpen(false)}>
          <BiX className="size-8" />
        </button>
      </div>
      <div className="px-8">
        <H4>Roles</H4>
        <div className="flex flex-wrap gap-x-1 gap-y-2 mb-4">
          {data &&
            data.myOrganization.roles?.map((role) => {
              const isSelected = role.name === selectedRoleName

              return (
                <button
                  type="button"
                  key={role.name}
                  className={clsx(
                    "rounded-full py-2 px-4 text-center ",
                    isSelected ? "bg-gray-800 text-white" : "bg-gray-100 text-gray-800"
                  )}
                  onClick={() => setRole(role)}
                >
                  {role.name}
                </button>
              )
            })}
        </div>
      </div>
      <div className="px-8">
        <H4>Permissions</H4>
        {permissionGroups.map((permissionGroup) => (
          <div key={permissionGroup.title} className="mb-6">
            <H5 className="text-gray-600">{permissionGroup.title}</H5>
            <ul>
              {permissionGroup.permissions.map((permission) => (
                <li key={permission} className="flex justify-between">
                  <pre>{permission}</pre>
                  <input
                    type="checkbox"
                    checked={currentUserPermissions.includes(permission)}
                    onChange={() => togglePermission(permission)}
                  />
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </SwipeableDrawer>
  )
}
