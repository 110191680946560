import { Cache } from "@urql/exchange-graphcache"
import { invalidateQuery } from "../../cacheHelpers"

export const createOrEditDivisionCache = (cache: Cache) => {
  cache
    .inspectFields("Query")
    .filter(
      (query) =>
        query.fieldName === "myDivisions" || query.fieldName === "divisions" || query.fieldName === "myOrganization"
    )
    .forEach((query) => invalidateQuery(cache, query))
}
