import { Cache } from "@urql/exchange-graphcache"
import { assetListStoreActions } from "../../../../stores/assetList"
import { invalidateQuery } from "../../cacheHelpers"

export const invalidateAssetCache = (cache: Cache) => {
  // Invalidate the cache for the assets queries
  const fieldsToUpdate: Record<string, boolean> = {
    assets_2: true,
    assets: true,
    asset: true,
    assignedAssets: true,
  }
  cache
    .inspectFields("Query")
    .filter((info) => fieldsToUpdate[info.fieldName])
    .forEach((fieldInfo) => {
      invalidateQuery(cache, fieldInfo)
    })

  assetListStoreActions.resetPagination()
}
