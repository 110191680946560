import { Button } from "@mui/material"
import Image from "next/image"
import { useContext } from "react"
import { BiBell } from "react-icons/bi"
import { BillingStatus } from "../../../graphql/generated/graphcache"
import { useOrganizationBillingStatus } from "../../../hooks/useOrganizationBillingStatus"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { H1 } from "../../deprecated"

export const BillingPastDueBanner = () => {
  const { linkToStripeInvoiceToPay, organizationBillingStatus } = useOrganizationBillingStatus()
  const { hasGrantedPermission } = useContext(PermissionsContext)

  if (organizationBillingStatus === BillingStatus.Active) return null

  if (organizationBillingStatus === BillingStatus.DisabledDueToNonPayment) {
    return (
      <div>
        <div className="fixed inset-0 h-screen overflow-auto bg-white py-2 px-8 z-30">
          <div className="max-w-5xl flex flex-col gap-12 mx-auto py-12 min-h-screen justify-center items-center">
            <div className="grid gap-4">
              <Image className="mx-auto" alt="CrewView" src="/logo-color.svg" width={208 * 1.2} height={40 * 1.2} />
              <Image
                className="mx-auto relative -left-12"
                alt="Sad you can't use CrewView"
                src="/disabled-due-to-non-payment.jpg"
                width={786}
                height={439}
              />
            </div>

            <div className="grid gap-4 text-center max-w-3xl">
              <H1 className="font-bold">Unfortunately, you can’t access CrewView right now.</H1>
              {/* non org admins don't have access to make a payment */}
              {!hasGrantedPermission("organization:update:self") ? (
                <>
                  <p>To get your access back up and running, please contact us.</p>
                  <div className="flex justify-center items-center gap-12 flex-wrap mt-4">
                    <p>
                      <a className="text-blue-600" href="mailto:support@crewview.com">
                        support@crewview.com
                      </a>
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <p>To get your access back up and running, please make a payment or contact us.</p>
                  <div className="flex justify-center items-center gap-12 flex-wrap mt-4">
                    <Button href={linkToStripeInvoiceToPay} variant="contained" color="primary">
                      Make a payment
                    </Button>
                    <p>
                      <a className="text-blue-600" href="mailto:support@crewview.com">
                        support@crewview.com
                      </a>
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  // only show the banner to org admins
  if (!hasGrantedPermission("organization:update:self")) return null

  return (
    <div className="fixed bg-blue-600 text-white flex gap-2 justify-center items-center w-full bottom-0 py-2 px-8 z-30">
      <BiBell className="text-xl shrink-0" />
      <p className="text-lg text-center">
        Please update your billing information. You can{" "}
        <a className="underline" href={linkToStripeInvoiceToPay}>
          update it here
        </a>{" "}
        or contact us at{" "}
        <a className="underline" href="mailto:support@crewview.com">
          support@crewview.com
        </a>
      </p>
    </div>
  )
}
