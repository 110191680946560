import Link from "next/link"
import { ReactElement, ReactNode, useContext } from "react"
import { DrawerContext, DrawerName } from "../../providers/DrawerProvider"

type DrawerLinkProps = {
  children: ReactNode
  className?: string
  component: ReactElement
  confirmOnClose?: boolean
  disabled?: boolean
  drawerName: DrawerName
  href?: string
}

export const DrawerLink = ({ children, className, component, disabled, drawerName, href }: DrawerLinkProps) => {
  const { push: pushDrawer } = useContext(DrawerContext)

  const ContainerComponentToRender = href ? Link : "button"

  return disabled ? (
    <div className={className}>{children}</div>
  ) : (
    <ContainerComponentToRender
      href={href || ""}
      onClick={(event) => {
        event.preventDefault()
        if (event.metaKey || event.ctrlKey) {
          window.open(href, "_blank")
        } else {
          pushDrawer(component, drawerName)
        }
      }}
      className={className}
    >
      {children}
    </ContainerComponentToRender>
  )
}
