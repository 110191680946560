import { OrganizationsIndexPageQuery } from "../graphql/generated/client-types-and-hooks"
import { DocumentModelType } from "../helpers/files/fileUpload"
import { sessionKeys } from "../lib/jwtHelpers"
import { FeatureToggleResponse } from "../lib/toggleService"
import { RefreshResponse, SessionUser } from "../services/auth"
import { ContentType } from "../types/ContentType"
import { Role } from "../types/Role"

type Options = {
  headers?: Record<string, string>
}

function getAuthHeader(): { headers: { authorization: string } } {
  return { headers: { authorization: localStorage.getItem(sessionKeys.accessToken) ?? "" } }
}

async function post<T>(url: string, body: Record<string, unknown> = {}, options: Options = {}): Promise<T> {
  const headers: RequestInit["headers"] = {
    "Content-Type": "application/json",
  }
  if (options?.headers?.authorization) headers["Authorization"] = options.headers.authorization

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    credentials: "same-origin",
    headers,
  })

  if (response.ok) return response.json()

  // Handle non-OK responses
  const textResponse = await response.text()
  throw new Error(textResponse)
}

async function get<T>(url: string, params?: Record<string, string>, options: Options = {}): Promise<T> {
  const query = new URLSearchParams(params)
  if (params && Object.keys(params).length > 0) url += `?${query.toString()}`

  const headers: RequestInit["headers"] = {}
  if (options?.headers?.authorization) headers["Authorization"] = options.headers.authorization

  const response = await fetch(url, {
    method: "GET",
    credentials: "same-origin",
    headers,
  })

  return response.json()
}

async function remove<T>(url: string, params?: Record<string, string>, options: Options = {}): Promise<T> {
  const query = new URLSearchParams(params)
  if (params) url += `?${query.toString()}`

  const headers: RequestInit["headers"] = {}
  if (options?.headers?.authorization) headers["Authorization"] = options.headers.authorization

  const response = await fetch(url, {
    method: "DELETE",
    credentials: "same-origin",
    headers,
  })

  return response.json()
}

export const checkSingleUseToken = async (token?: string) => {
  const body: Record<string, string> = token ? { token } : {}
  const data = await get("/api/auth/check_single_use_token", body)
  return data as { valid: boolean }
}

export const refreshSession = async (refreshToken: string | null) => {
  const accessToken = localStorage.getItem(sessionKeys.accessToken)
  const data: RefreshResponse = await post("/api/auth/refresh", { refreshToken, accessToken })
  if (data.accessToken) localStorage.setItem(sessionKeys.accessToken, data.accessToken)
  if (data.refreshToken) localStorage.setItem(sessionKeys.refreshToken, data.refreshToken)

  return data
}

export const fetchSession = async () => {
  const data = await get("/api/auth/user_session", {}, getAuthHeader())
  return data as { user: SessionUser }
}

export const getFeatureFlags = async (email?: string): Promise<FeatureToggleResponse> => {
  const data: Record<string, string> = email ? { email } : {}
  return get("/api/auth/feature_flags", data, getAuthHeader())
}

export const logout = async (params: { allSessions?: boolean } = {}) => {
  return get("/api/auth/logout", params as Record<string, string>, getAuthHeader())
}

type LoginParams =
  | {
      email: string
      password: string
      persistSession?: boolean
    }
  | {
      token: string
    }
export const login = async (params: LoginParams) => {
  const data: {
    accessToken: string
    refreshToken: string
  } = await post("/api/auth/login", params)
  if (data.accessToken) localStorage.setItem(sessionKeys.accessToken, data.accessToken)
  if (data.refreshToken) localStorage.setItem(sessionKeys.refreshToken, data.refreshToken)
}

export const resetPasswordRequest = async (email: string) => {
  return post("/api/auth/send_password_reset_email", { email })
}

export const sendLoginLink = async (email: string) => {
  return post("/api/auth/send_login_link", { email })
}

export const resetPassword = async (password: string, token: string) => {
  return post("/api/auth/reset_password", { password, token })
}

export const getEmailStatus = async (email: string) => {
  const data = await get("/api/auth/email_status", { email })
  return data as { passwordResetRequired: boolean }
}

export const addOrganization = async (
  id: string,
  name: string,
  timezone: string,
  defaultRoles: Role[],
  image?: string
) => {
  const data = await post(
    "/api/add-organization",
    {
      id,
      name,
      image,
      timezone,
      defaultRoles,
    },
    getAuthHeader()
  )

  return data as OrganizationsIndexPageQuery["organizations"][0]
}

export const getFileUploadSignedPutUrl = async (name: string, type: string) => {
  const data = await post<{ url: string; objectKey: string; expiration: string; fileId: string }>(
    "/api/file",
    {
      name,
      type,
    },
    getAuthHeader()
  )
  return data
}

export const getDocumentSignedPutUrl = async (
  modelType: DocumentModelType,
  modelId: string,
  name: string,
  type: string,
  expiresAt?: Date | string
) => {
  const data = await post<{
    url: string
    objectKey: string
    expiration: string
    fileId: string
    modelId: string
    expiresAt?: string
  }>(
    "/api/document-signed-put-url",
    {
      name,
      type,
      modelId,
      modelType,
      expiresAt,
    },
    getAuthHeader()
  )

  return data
}

export const deleteFile = async (fileId: string) => {
  const data = await remove("/api/file", { fileId }, getAuthHeader())
  return data
}

export const getTimeEntryEvidenceSignedPutUrl = async (contentType: ContentType, userId: string) => {
  const data = await get<{ url: string; path: string }>(
    "/api/time-entry-evidence-signed-put-url",
    {
      contentType,
      id: userId,
    },
    getAuthHeader()
  )
  return data
}

export const getInjuryReportSignedPutUrl = async (contentType: ContentType, injuryReportId: string) => {
  const data = await get<{ url: string; path: string }>(
    "/api/injury-report-signed-put-url",
    {
      contentType,
      injuryReportId,
    },
    getAuthHeader()
  )
  return data
}
