import { FC } from "react"
import { navigation } from "./data/navigation"
import { DesktopMenu } from "./DesktopMenu"
import { MobileMenu } from "./MobileMenu"

export type NavsProps = {
  sidebarOpen: boolean
  setSidebarOpen: (value: boolean) => void
}

const Navbar: FC<NavsProps> = ({ sidebarOpen, setSidebarOpen }) => {
  return (
    <>
      <MobileMenu navigation={navigation} setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
      <DesktopMenu navigation={navigation} />
    </>
  )
}

export default Navbar
