import { Store } from "@tanstack/react-store"

type WarnOnExitStore = {
  hasUnsavedChanges: boolean
  showExitModal: boolean
  onConfirmExit?: () => void
}

export const warnOnExitStore = new Store<WarnOnExitStore>({
  hasUnsavedChanges: false,
  showExitModal: false,
  onConfirmExit: undefined,
})

export const warnOnExitStoreActions = {
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => {
    if (hasUnsavedChanges !== warnOnExitStore.state.hasUnsavedChanges) {
      warnOnExitStore.setState((state) => ({ ...state, hasUnsavedChanges }))
    }
  },

  confirmExit: (confirmExitCallback: () => void) => {
    // if there are unsaved changes, show the exit modal
    if (warnOnExitStore.state.hasUnsavedChanges) {
      warnOnExitStore.setState((state) => ({ ...state, showExitModal: true, onConfirmExit: confirmExitCallback }))

      return
    }

    // if no unsaved changes, call the callback (exit without warning)
    confirmExitCallback()
  },

  hideExitModal: () => {
    warnOnExitStore.setState((state) => ({ ...state, showExitModal: false }))
  },

  clear: () => {
    warnOnExitStore.setState((state) => ({
      ...state,
      hasUnsavedChanges: false,
      showExitModal: false,
      onConfirmExit: undefined,
    }))
  },
}
