import { clsx } from "clsx"
import { FC, ReactNode } from "react"
import { overrideTailwindClasses } from "tailwind-override"

type Props = {
  children: ReactNode
  className?: string
}

/** @deprecated use MUI Typography component with variant="h5" instead */
export const H5: FC<Props> = ({ children, className = "", ...props }) => (
  <h5 {...props} className={overrideTailwindClasses(clsx("text-xl text-gray-800 font-bold mt-2 mb-4", className))}>
    {children}
  </h5>
)
