// JWT constants
import jwt from "jsonwebtoken"
import ms from "ms"
import { StandardClaimsResponse } from "../services/auth/jwt"

const toSeconds = (value: string) => ms(value) / 1000

export const accessTokenExp = process.env.ACCESS_TOKEN_EXP || "1h"
export const singleUseTokenExp = process.env.SINGLE_USE_TOKEN_EXP || "7d"
export const algorithm = "RS256"
export const alg = algorithm
// The default refresh window is 10% of the total life of the token.
// Example: if the token expires in 1 hour, refresh it 6 min before it expires.
const defaultRefreshPadding = toSeconds(accessTokenExp) * 0.1

export const sessionKeys = {
  accessToken: "access-token",
  refreshToken: "refresh-token",
  accessTokenExp: "access-token-exp",
}

export function shouldRefresh(exp: number, paddingInSeconds: number = defaultRefreshPadding): boolean {
  const nowInSeconds = Number((Date.now() / 1000).toFixed())
  const secondsToExpiration = exp - nowInSeconds
  return secondsToExpiration < paddingInSeconds
}

export const getSessionExp = () => {
  const accessToken = localStorage.getItem(sessionKeys.accessToken)
  const decoded = accessToken ? (jwt.decode(accessToken) as StandardClaimsResponse) : undefined
  return decoded?.exp
}

export function expiresIn(exp: number) {
  const nowInSeconds = Number((Date.now() / 1000).toFixed())
  return exp - nowInSeconds
}
