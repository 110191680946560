import { FC } from "react"
import { ModalProps } from "../hooks/useModalProps"
import { MuiModal } from "./Elements/MuiModal"
import { Box, Typography } from "@mui/material"

export const DownloadMobileAppModal: FC<{
  modalProps: ModalProps
}> = ({ modalProps }) => {
  const downloadAppData = {
    googlePlayStore: {
      href: "https://play.google.com/store/apps/details?id=com.crewview.android&pcampaignid=web_share&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
      src: "https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png",
      alt: "Get it on Google Play",
    },
    appStore: {
      href: "https://apps.apple.com/us/app/crewview-mobile/id6470239131?itsct=apps_box_badge&amp;itscg=30200",
      src: "https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1703030400",
      alt: "Download on the App Store",
    },
  }

  return (
    <MuiModal
      contentLabel="Download CrewView Mobile!"
      variant="small"
      showActions={false}
      isOpen={modalProps.isOpen}
      handleCloseModal={() => {
        modalProps.handleCloseModal()
      }}
    >
      <Box className="flex flex-col gap-4 px-10 ">
        <Typography lineHeight={2}>
          Designed specifically for workers in the field, the CrewView app simplifies team management and progress
          tracking. With our intuitive interface, you can efficiently organize on-site operations and keep your team
          aligned every step of the way.
        </Typography>
        <div className="flex flex-row items-center mx-auto">
          <a href={downloadAppData.appStore.href}>
            <img
              src={downloadAppData.appStore.src}
              alt={downloadAppData.appStore.alt}
              style={{ width: "170px", padding: "11px" }}
            />
          </a>
          <a href={downloadAppData.googlePlayStore.href}>
            <img
              alt={downloadAppData.googlePlayStore.alt}
              src={downloadAppData.googlePlayStore.src}
              style={{ width: "182px" }}
            />
          </a>
        </div>
        {/* TODO: configure deep linking to ios or android app from mobile web */}
        {/* <Typography>
          Already have the app? Open on <Link href={downloadAppData.appStore.href}>iOS</Link> or on{" "}
          <Link href={downloadAppData.googlePlayStore.href}>Android</Link>
        </Typography> */}
      </Box>
    </MuiModal>
  )
}
