import { clsx } from "clsx"
import { FC, ReactNode } from "react"
import { overrideTailwindClasses } from "tailwind-override"

type Props = {
  children: ReactNode
  className?: string
}

/** @deprecated use MUI Typography component with variant="h4" instead */
export const H4: FC<Props> = ({ children, className = "", ...props }) => (
  <h4 {...props} className={overrideTailwindClasses(clsx("text-2xl text-gray-800 font-bold mt-2 mb-6", className))}>
    {children}
  </h4>
)
