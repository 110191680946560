import { Cache } from "@urql/exchange-graphcache"
import { MutationReassignUserArgs } from "../../../graphql/generated/client-types-and-hooks"
import { User, WithTypename } from "../../../graphql/generated/graphcache"
import { addToQuery, removeFromQuery } from "../cacheHelpers"
import { invalidateAssetCache } from "./utils/asset.util"
import { invalidateTaskCache } from "./utils/task.util"
import { invalidateUsersListCache, updateCacheWithUserData } from "./utils/user.utils"

type Result = {
  reassignUser: WithTypename<User>
}

/**
 * Updates the graphcache when reassignUser mutation is called.
 * @param result The result object containing the user.
 * @param args The arguments for user reassignment.
 * @param cache The graphcache instance.
 */
export const reassignUser = (result: Result, args: MutationReassignUserArgs, cache: Cache) => {
  const newData = prepareIndividualUserData(result, args)

  invalidateTaskCache(cache)
  invalidateAssetCache(cache)
  invalidateUsersListCache(cache)
  updateCacheWithUserData(newData, cache)
  updateUserConnections(newData, cache)
}

/**
 * Prepares user data by combining the results and arguments.
 * @param result The result object containing the user.
 * @param args The arguments for user reassignment.
 * @returns The prepared user data.
 */
const prepareIndividualUserData = (result: Result, args: MutationReassignUserArgs): WithTypename<User> => {
  return {
    ...{ id: args.userId, currentTaskId: args.taskId, taskId: args.taskId, latestTimeEntry: {}, __typename: "User" },
    ...result.reassignUser,
  } as unknown as WithTypename<User>
}

/**
 * Updates user connections in the cache based on task assignment.
 *
 * @param userData The new data for the user.
 * @param cache The graphcache instance.
 */
const updateUserConnections = (userData: WithTypename<User>, cache: Cache) => {
  const userListQueryNames: Record<string, boolean> = { usersList: true, users: true }

  // Update any queries we can find that might be affected by this change.
  const userKey = cache.keyOfEntity({ id: userData.id || "", __typename: "User" })
  const connectionQueries = cache.inspectFields("Query").filter((field) => userListQueryNames[field.fieldName])

  connectionQueries.forEach((query) => {
    if (
      (query.arguments?.taskId && query.arguments?.taskId !== userData.currentTaskId) ||
      (query.arguments?.projectId && query.arguments?.projectId !== userData.currentProjectId)
    ) {
      removeFromQuery(cache, query, userKey)
    } else {
      addToQuery(cache, query, userData)
    }
  })
}
