import { StyledEngineProvider, ThemeProvider } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LicenseInfo } from "@mui/x-license-pro"
import { NextComponentType, NextPage, NextPageContext } from "next"
import { AppProps } from "next/app"
import Head from "next/head"
import Script from "next/script"
import { SnackbarProvider } from "notistack"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"
import { FC, ReactNode, useContext, useEffect, useState } from "react"
import { Provider as UrQLProvider } from "urql"
import { SnackbarProviderActions, ThemedSnackbars } from "../components/Notistack/ThemedSnackbars"
import { urqlClient } from "../lib/urql"
import {
  DevelopmentFeatureFlagContext,
  DevelopmentFeatureFlagProvider,
} from "../providers/DevelopmentFeatureFlagProvider"
import { ModalProvider } from "../providers/ModalProvider"
import { NotificationsProvider } from "../providers/NotificationsProvider"
import { SessionContext, SessionProvider } from "../providers/SessionProvider"
import "../styles/globals.css"
import { muiTheme } from "../styles/mui-theme"
import Router from "./_router"
import { useModalProps } from "../components/Modals/hooks/useModalProps"
import { useStore } from "@tanstack/react-store"
import { warnOnExitStore, warnOnExitStoreActions } from "../stores/warnOnExit"
import { useNavigationObserver } from "../hooks/useNavigationObserver"
import { ConfirmCancelModal } from "../components/Modals/ConfirmCancelModal"
import { NewDeploymentAvailableDialog } from "../components/NewDeploymentAvailableDialog"
import { Roboto_Flex } from "next/font/google"

if (typeof window !== "undefined") {
  // checks that we are client-side
  if (process.env.NEXT_PUBLIC_POSTHOG_KEY) {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
      api_host: "/ingest",
      ui_host: "https://us.posthog.com",
      person_profiles: "identified_only",
    })
  }
}

LicenseInfo.setLicenseKey(
  "acd680eccf859cca56024bd281b2a956Tz05MTQ5OCxFPTE3NDg1NDkwOTUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
)

export type PageProps = Record<string, string>
const roboto = Roboto_Flex({
  subsets: ["latin"],
  display: "swap",
})

const ClientOnly: FC<{ children: ReactNode }> = ({ children, ...delegated }) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return <div {...delegated}>{children}</div>
}

const App: NextPage<AppProps<PageProps>> = ({ Component, pageProps }) => {
  const confirmCancelModalProps = useModalProps("Unsaved Data")
  const { hasUnsavedChanges, showExitModal } = useStore(warnOnExitStore)
  const { confirmExit } = warnOnExitStoreActions

  const confirmNavigation = useNavigationObserver({
    shouldStopNavigation: hasUnsavedChanges,
    onNavigate: () => {
      confirmExit(() => {
        confirmNavigation()
        confirmCancelModalProps.handleCloseModal()
      })
    },
  })

  useEffect(() => {
    if (hasUnsavedChanges && showExitModal) {
      confirmCancelModalProps.handleOpenModal()

      return
    }

    confirmCancelModalProps.handleCloseModal()
  }, [hasUnsavedChanges, showExitModal, confirmCancelModalProps])

  return (
    <PostHogProvider client={posthog}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <Head>
            <title>CrewView by Blackthorn Software</title>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
          </Head>
          <style jsx global>{`
            :root {
              --font-roboto-flex: ${roboto.style.fontFamily};
            }
          `}</style>
          <ClientOnly>
            {process.env.NEXT_PUBLIC_IS_PRODUCTION === "true" && (
              <>
                <Script id="google-tag-manager" strategy="afterInteractive">
                  {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-P2KRB67');
        `}
                </Script>
                <noscript>
                  <iframe
                    src="https://www.googletagmanager.com/ns.html?id=GTM-P2KRB67"
                    height="0"
                    width="0"
                    style={{ display: "none", visibility: "hidden" }}
                  />
                </noscript>
              </>
            )}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SessionProvider>
                <DevelopmentFeatureFlagProvider>
                  <>
                    <Authenticated Component={Component} pageProps={pageProps} />
                    {confirmCancelModalProps.isOpen && <ConfirmCancelModal modalProps={confirmCancelModalProps} />}
                  </>
                </DevelopmentFeatureFlagProvider>
              </SessionProvider>
            </LocalizationProvider>
          </ClientOnly>
        </ThemeProvider>
      </StyledEngineProvider>
    </PostHogProvider>
  )
}

function Authenticated({
  Component,
  pageProps,
}: {
  Component: NextComponentType<NextPageContext, unknown, PageProps>
  pageProps: PageProps
}) {
  const session = useContext(SessionContext)
  const featureFlagContext = useContext(DevelopmentFeatureFlagContext)
  return (
    <UrQLProvider value={urqlClient(session, featureFlagContext)}>
      <ModalProvider>
        <NotificationsProvider>
          <SnackbarProvider
            Components={ThemedSnackbars}
            action={(snackbarId) => <SnackbarProviderActions snackbarId={snackbarId} />}
            autoHideDuration={5000}
            maxSnack={3}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          >
            <Router Component={Component} pageProps={pageProps} />

            <NewDeploymentAvailableDialog />
          </SnackbarProvider>
        </NotificationsProvider>
      </ModalProvider>
    </UrQLProvider>
  )
}

export default App
