import { Box, Button, Typography } from "@mui/material"
import { format, isToday, isYesterday } from "date-fns"
import Link from "next/link"
import { useContext } from "react"
import { BiHealth, BiTask, BiWrench } from "react-icons/bi"
import {
  useMarkAllNotificationsReadMutation,
  useMarkNotificationsReadByIdMutation,
} from "../../../../graphql/generated/client-types-and-hooks"
import { colors } from "../../../../helpers/styles/colors"
import {
  NOTIFICATION_TYPES,
  NotificationsContext,
  UserNotificationExpectation,
} from "../../../../providers/NotificationsProvider"
import { DrawerBody } from "./Elements/DrawerBody"
import { DrawerHeader } from "./Elements/DrawerHeader"

export const NotificationsDrawer = () => {
  const { notifications } = useContext(NotificationsContext)

  const [_, markAllRead] = useMarkAllNotificationsReadMutation()

  const hasUnreadNotifications = notifications?.some((notification) => !notification.markedReadAt)

  return (
    <>
      <DrawerHeader />

      <DrawerBody>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" color={colors.gray[800]} fontSize={32} fontWeight={700}>
            Notifications
          </Typography>
          <Typography>
            <Button
              className="focus:outline-none"
              disabled={!hasUnreadNotifications}
              onClick={() => markAllRead({})}
              variant="text"
            >
              Mark all as read
            </Button>
          </Typography>
        </Box>
        {notifications?.length === 0 && <div>No notifications</div>}
        <div className="border-t mt-6">
          {notifications &&
            notifications.map((notification) => (
              <UserNotificationRow key={notification.id} notification={notification} />
            ))}
        </div>
      </DrawerBody>
    </>
  )
}

export const UserNotificationRow = ({
  notification,
  navigateOnClick = true,
}: {
  notification: UserNotificationExpectation
  navigateOnClick?: boolean
}) => {
  const [_, markRead] = useMarkNotificationsReadByIdMutation()

  const buildHref = () => {
    switch (notification.modelType) {
      case "Asset":
        return `/assets/${notification.asset?.id}/history`
      case "User":
        return `/team/${notification.user?.id}/time-cards`
    }

    return ``
  }

  if (!navigateOnClick) {
    return (
      <button
        className="flex w-full text-left gap-4 items-start py-2 border-b text-gray-800"
        onClick={() => markRead({ ids: [notification.id] })}
      >
        <RowContent notification={notification} />
      </button>
    )
  }

  return (
    <Link
      className="flex w-full text-left gap-4 items-start py-2 border-b text-gray-800"
      href={buildHref()}
      onClick={() => markRead({ ids: [notification.id] })}
    >
      <RowContent notification={notification} />
    </Link>
  )
}

const RowContent = ({ notification }: { notification: UserNotificationExpectation }) => {
  const RenderIcon = () => {
    const iconClassNames = "w-5 h-5"
    switch (notification.type) {
      case "ASSET_INSPECTION_FAILED":
        return <BiWrench className={iconClassNames} />
      case "INJURY_REPORT_CREATED":
        return <BiHealth className={iconClassNames} />
    }

    return <BiTask className={iconClassNames} />
  }

  const RenderSubLabel = () => {
    switch (notification.modelType) {
      case "Asset":
        return `${notification.asset?.name}`
      case "User":
        return `${notification.user?.firstName} ${notification.user?.lastName}`
    }

    return ``
  }

  const getDateText = () => {
    if (isToday(notification.createdAt)) {
      return "Today"
    }
    if (isYesterday(notification.createdAt)) {
      return "Yesterday"
    }

    return format(notification.createdAt, "LLL do")
  }

  const notificationTypeLabel = NOTIFICATION_TYPES[notification.type]
  return (
    <>
      <Box
        alignItems="center"
        bgcolor={colors.gray["100"]}
        className="rounded-lg"
        display="flex"
        height="40px"
        justifyContent="center"
        width="40px"
      >
        <RenderIcon />
      </Box>
      <div className="grid gap-2">
        <div className="flex items-center gap-2">
          {!notification.markedReadAt && <div className="size-2 rounded-full bg-orange-600" />}
          <Typography fontWeight={600} fontSize="16px">
            {notificationTypeLabel || notification.type}
          </Typography>
          <Typography fontSize="16px">
            <RenderSubLabel />
          </Typography>
        </div>
        <div>
          <Typography color={colors.gray[600]} fontSize="12px" fontWeight="400">
            {getDateText()}
          </Typography>
        </div>
      </div>
    </>
  )
}
