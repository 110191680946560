import { format } from "date-fns"
import { FC } from "react"
import { BiCalendar } from "react-icons/bi"
import { H2 } from "../../components/deprecated"
import { OrganizationImage } from "../../components/OrganizationImage"
import { Organization, useDivisionLinkMenuDataQuery } from "../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../types/helpers"

type Props = {
  org: PickPlus<Organization, "createdAt" | "imageUrl" | "name">
}

const OrganizationHeader: FC<Props> = ({ org }) => {
  const [{ data }] = useDivisionLinkMenuDataQuery({
    requestPolicy: "cache-only",
  })

  if (!org) {
    return <></>
  }

  const divisionName = data?.myDivision.name || org.name
  const divisionImageUrl = data?.myDivision.imageUrl || org.imageUrl

  return (
    <div className="flex mb-4 gap-x-4">
      <OrganizationImage organization={{ ...org, imageUrl: divisionImageUrl }} />
      <div className="md:ml-4 flex flex-col justify-center truncate">
        <H2 className="text-gray-800 truncate my-0 w-full">{divisionName}</H2>
        {org.createdAt && (
          <div className="flex items-center gap-0.5 text-gray-400">
            <BiCalendar />
            <div className="truncate">Created {format(new Date(org.createdAt), "MMMM yyyy")}</div>
          </div>
        )}
      </div>
    </div>
  )
}
export default OrganizationHeader
