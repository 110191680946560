import { Transition } from "@headlessui/react"
import { clsx } from "clsx"
import { useRouter } from "next/router"
import { FC, useContext, useMemo } from "react"
import { DevelopmentFeatureFlagName } from "../../helpers/api/developmentFeatureFlags"
import { useWindowSize } from "../../hooks/useWindowSize"
import { DevelopmentFeatureFlagContext } from "../../providers/DevelopmentFeatureFlagProvider"
import { PermissionsContext } from "../../providers/PermissionsProvider/PermissionsProvider"
import { useCurrentUser } from "../../providers/PermissionsProvider/currentUserProvider"
import { CheckedPermission } from "../../types/Permission"

export type Tab = {
  label: string
  href: string
  requiredPermission?: CheckedPermission | CheckedPermission[]
  requiredFeatureFlag?: DevelopmentFeatureFlagName
  hideOnMobile?: boolean
}

type Props = {
  tabs: Tab[]
}

const Tab: FC<Tab> = ({ label, href }) => {
  const router = useRouter()

  const isSelected = router.pathname === href

  return (
    <button
      className={clsx("relative my-2 md:my-8 pb-2 font-bold uppercase", !isSelected && "text-gray-400")}
      onClick={() => {
        router.replace(
          {
            pathname: href,
            query: router.query,
          },
          undefined,
          { scroll: false }
        )
      }}
    >
      {label}
      <Transition
        appear={true}
        show={true}
        enter="transition ease-in-out duration-150 transform"
        enterFrom="opacity-0 translate-y-full"
        enterTo="opacity-100 translate-y-0"
      >
        <div
          className={clsx(
            "w-full relative top-1 h-1 transition-all rounded-sm",
            isSelected ? "bg-blue-600" : "bg-transparent"
          )}
        />
      </Transition>
    </button>
  )
}

/**
 * @deprecated Please use `MuiTabs`
 */
export const DeprecatedTabGroup: FC<Props> = ({ tabs }) => {
  const { hasPermissionTo } = useContext(PermissionsContext)
  const { flagIsEnabled } = useContext(DevelopmentFeatureFlagContext)
  const { organizationId } = useCurrentUser()
  const { width: browserWidth } = useWindowSize()
  const isMobile = useMemo(() => (browserWidth || 0) < 768, [browserWidth])

  const filteredTabs = tabs.filter((tab) => {
    // Check for permission requirements
    const hasRequiredPermission = tab.requiredPermission
      ? hasPermissionTo(tab.requiredPermission, { organizationId })
      : true

    // Check for feature flag requirements
    const hasRequiredFeatureFlag = tab.requiredFeatureFlag ? flagIsEnabled(tab.requiredFeatureFlag) : true

    // Check for mobile visibility
    const shouldShowOnMobile = isMobile ? !tab.hideOnMobile : true

    return hasRequiredPermission && hasRequiredFeatureFlag && shouldShowOnMobile
  })

  return tabs.length > 0 ? (
    <div className="relative">
      <div className="flex gap-6 overflow-x-auto">
        {filteredTabs.map((tab) => {
          return <Tab key={tab.href} {...tab} />
        })}
      </div>
    </div>
  ) : null
}
