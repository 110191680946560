import { createContext, FC, ReactNode, useCallback, useContext, useState } from "react"
import { WidgetConfigGroup } from "../../components/Widgets/availableWidgets"
import { checkForPermission, getAllowedScopesForUser } from "../../helpers/permissions/checkForPermission"
import { CheckedPermission, GrantedPermission } from "../../types/Permission"
import { Scope } from "../../types/Scope"
import { useCurrentUser } from "./currentUserProvider"
import { PermissionsExplorer } from "./PermissionsExplorer"

export type UserProjectAssignments = {
  id: string
  organizationId: string
  divisionId?: string | null
  projectId: string
  updatedAt: Date
  taskId: string | null
  isCurrentAssignment: boolean | null
  active: boolean
  createdAt: Date
  userId: string
}[]

export type DecisionContext = {
  projectId?: string | string[] | null
  taskId?: string | string[] | null
  userId?: string | null
  customerId?: string | null
  organizationId?: string | null
  divisionId?: string | null
  assignableId?: string | null
  userProjectAssignments?: UserProjectAssignments | null
  vendorId?: string | null
}

type PermissionsProviderProps = {
  children: ReactNode
}

type PermissionsContextType = {
  effectiveWidgets: WidgetConfigGroup[]
  getAllowedScopes: (requestedPerm: CheckedPermission | CheckedPermission[]) => Scope[]
  hasGrantedPermission: (perm: GrantedPermission) => boolean
  hasPermissionTo: (
    permission: CheckedPermission | CheckedPermission[] | undefined,
    context?: DecisionContext
  ) => boolean
}

export const PermissionsContext = createContext<PermissionsContextType>({} as PermissionsContextType)

export const PermissionsProvider: FC<PermissionsProviderProps> = ({ children }) => {
  const currentUser = useCurrentUser()

  const [currentUserPermissions, setCurrentUserPermissions] = useState<GrantedPermission[]>(
    (currentUser.roles.map((role) => role.permissions).flat() as GrantedPermission[]) || []
  )
  const [effectiveWidgets, setEffectiveWidgets] = useState<WidgetConfigGroup[]>(
    currentUser.roles.reduce(
      (acc, role) => [...acc, ...(role.widgets as WidgetConfigGroup[])],
      [] as WidgetConfigGroup[]
    )
  )

  const hasPermissionTo = useCallback(
    (permission: CheckedPermission | CheckedPermission[] | undefined, context: DecisionContext = {}) => {
      if (permission === undefined) {
        return false
      }
      const permsToCheck = Array.isArray(permission) ? permission : [permission]
      return permsToCheck.some((permToCheck) =>
        checkForPermission(currentUser, currentUserPermissions as GrantedPermission[], permToCheck, context)
      )
    },
    [currentUser, currentUserPermissions]
  )

  const hasGrantedPermission = (p: GrantedPermission) => currentUserPermissions.includes(p)

  const getAllowedScopes = (requestedPerm: CheckedPermission | CheckedPermission[]) =>
    getAllowedScopesForUser(currentUser, currentUserPermissions, requestedPerm)

  return (
    <PermissionsContext.Provider value={{ effectiveWidgets, getAllowedScopes, hasGrantedPermission, hasPermissionTo }}>
      {children}
      {process.env.NODE_ENV === "development" && (
        <PermissionsExplorer
          currentUserPermissions={currentUserPermissions}
          setCurrentUserPermissions={setCurrentUserPermissions}
          roles={currentUser.roles}
          setEffectiveWidgets={setEffectiveWidgets}
        />
      )}
    </PermissionsContext.Provider>
  )
}

export const usePermissions = () => useContext(PermissionsContext)
