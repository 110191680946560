import { clsx } from "clsx"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { BiX } from "react-icons/bi"

export const MobileAppDownloadBanner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(false)
  const { query } = useRouter()

  useEffect(() => {
    if (typeof window === "undefined") return

    if (window.localStorage.getItem("crewview--app-banner-dismissed") === "true") return

    if (!window?.innerWidth) return

    const isMobileUserAgent = !!window.navigator.userAgent.match(/Mobile|iP(hone|od|ad)|Android/)

    setIsBannerVisible(isMobileUserAgent && window.innerWidth < 768)
  }, [])

  useEffect(() => {
    if (query.utm_source === "crewview_mobile") handleDismiss()
  }, [query.utm_source])

  const handleDismiss = () => {
    setIsBannerVisible(false)
    window.localStorage.setItem("crewview--app-banner-dismissed", "true")
  }

  return (
    <div
      className={clsx(
        "flex justify-between bottom-0 inset-x-0 bg-blue-600 text-white p-4 text-center text-lg z-20",
        isBannerVisible ? "fixed" : "hidden"
      )}
    >
      <p>
        <a href="https://www.crewview.com/mobile-app">
          <span>
            Download our mobile app for a <span className="text-nowrap">better experience</span>
          </span>
        </a>
      </p>
      <button className="text-3xl" onClick={handleDismiss}>
        <BiX />
      </button>
    </div>
  )
}
