import { Box } from "@mui/material"
import Button from "@mui/material/Button"
import { useRouter } from "next/router"
import { FC } from "react"
import { BiArrowBack, BiRefresh } from "react-icons/bi"
import { BsExclamationTriangle } from "react-icons/bs"

type Props = {
  resetError: () => void
}

const ErrorPage: FC<Props> = ({ resetError }) => {
  const router = useRouter()

  return (
    <Box className="flex flex-col justify-center items-center m-0 p-0" sx={{ height: "calc(100vh - 224px)" }}>
      <Box className="pb-5">
        <BsExclamationTriangle className="size-[40px] m-auto" />
      </Box>
      <h2 className="pb-1 font-bold text-2xl">Sorry, we encountered an error.</h2>
      <p className="pb-5 text-sm">Try refreshing the page or try again later.</p>
      <Box className="pb-3">
        <Button variant="contained" color="primary" endIcon={<BiRefresh />} onClick={() => resetError()}>
          Try again
        </Button>{" "}
        <Button variant="contained" color="primary" endIcon={<BiArrowBack />} onClick={() => router.back()}>
          Go back
        </Button>
      </Box>
      <p>
        <Button color="primary" onClick={() => router.push("/")}>
          Return to home
        </Button>
      </p>
    </Box>
  )
}

export default ErrorPage
