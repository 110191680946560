import resolveConfig from "tailwindcss/resolveConfig.js"
import tailwindConfig from "../../tailwind.config.js"

const fullConfig = resolveConfig(tailwindConfig)

export const colors = fullConfig.theme.colors

export const randomColor = [
  "#f97316",
  "#22c55e",
  "#0ea5e9",
  "#a855f7",
  "#f59e0b",
  "#10b981",
  "#3b82f6",
  "#d946ef",
  "#eab308",
  "#14b8a6",
  "#6366f1",
  "#ec4899",
  "#84c616",
  "#06b6d4",
  "#8b5cf6",
  "#f43f5e",
  "#ea580c",
  "#16a34a",
  "#0284c7",
  "#9333ea",
  "#d97706",
  "#059669",
  "#2563eb",
  "#c026d3",
  "#ca8a04",
  "#0d9488",
  "#4f46e5",
  "#db2777",
  "#65a30d",
  "#0891b2",
  "#7c3aed",
  "#e11d48",
  "#c2410c",
  "#15803d",
  "#0369a1",
  "#7e22ce",
  "#b45309",
  "#047857",
  "#1d4ed8",
  "#a21caf",
  "#a16207",
  "#0f766e",
  "#433c9a",
  "#be185d",
  "#4d7c0f",
  "#0e7490",
  "#6d28d9",
  "#be123c",
]
