import { sentenceCase } from "./sentenceCase"

const namespaceToString = (namespace: string): string => {
  switch (namespace) {
    case "timeEntry":
      return "time entry"

    default:
      return namespace.replaceAll("-", " ")
  }
}

const scopeToString = (scope: string): string => {
  switch (scope) {
    case "*":
      return "any"

    default:
      return scope.replaceAll("-", " ")
  }
}

const actionToString = (action: string): string => {
  switch (action) {
    case "clockIn":
      return "clock in"

    case "clockInOut":
      return "clock in/out"

    case "read":
      return "view"

    default:
      return action.replaceAll("-", " ")
  }
}

const applyOverrides = (string: string) => {
  return string
    .replace("create any", "create a")
    .replace("clock in any time entry", "clock in any user")
    .replace("clock in assigned project time entry", "clock in users on assigned projects")
    .replace("clock in self time entry", "clock in self")
    .replace("assigned project time entry", "time entry on assigned projects")
    .replace("assign assigned project user", "assign users to assigned projects")
    .replace("view assigned project user", "view users on assigned projects")
    .replace("assigned project task", "tasks on assigned projects")
    .replace("a asset", "an asset")
    .replace("project project", "project")
    .replace("task task", "task")
    .replace("documents", "manage documents on")
}

export const getHumanReadablePermissionName = (permissionName: string): string => {
  // if a string is passed that doesn't match the pattern
  // let's just return it sentence cased
  if (!permissionName.includes(":")) {
    return sentenceCase(permissionName)
  }

  const [_namespace, _action, _scope] = permissionName.split(":")

  // don't know what to do if we don't have these pieces
  if (!_namespace || !_action || !_scope) {
    return sentenceCase(permissionName.replaceAll(":", " "))
  }

  if (permissionName === "app:login:self") {
    return sentenceCase("login")
  }

  // break out the pieces
  const namespace = namespaceToString(_namespace)
  const action = actionToString(_action)
  const scope = scopeToString(_scope)

  return sentenceCase(applyOverrides(`${action} ${scope} ${namespace}`))
}
