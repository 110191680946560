export const URLs = {
  companyDetails: "/organization/details",
  divisions: "/organization/divisions",
  admins: "/organization/admins",
  roles: "/organization/roles",
  divisions: "/organization/divisions",
  units: "/organization/units",
  workersCompCodes: "/organization/workers-comp-codes",
  settings: "/organization/settings",
  profile: "/team/",
  helpCenter: "https://crewview-help-center.super.site/",
  reportBug: "https://docs.google.com/forms/d/e/1FAIpQLSc2sp1IJFHZcmQJMdf4Wlmq2Ih947AJqoACAU3aoGQndE3hSg/viewform",
}
