import { Cache, Data } from "@urql/exchange-graphcache"
import { addManyToQuery, addToQuery } from "../cacheHelpers"
import { MutationInsertManyAssetReportsArgs } from "../../../graphql/generated/client-types-and-hooks"
import { AssetReport, WithTypename } from "../../../graphql/generated/graphcache"
import { gql } from "urql"

type Result = {
  insertManyAssetReports: WithTypename<AssetReport>[]
}

export const insertManyAssetReports = (result: Result, args: MutationInsertManyAssetReportsArgs, cache: Cache) => {
  return result.insertManyAssetReports.forEach((report, index) => {
    const reportArg = args.reports[index]
    const newReport: Data = { ...reportArg, ...report, __typename: "AssetReport" }
    const fieldsToUpdate: Record<string, boolean> = {
      assetReports: true,
      assetRepairRequests: true,
    }
    const reporterId = report?.reporterId ?? null
    const assetId = report?.assetId ?? null
    const organizationId = report?.organizationId ?? null
    const inspectionReports: AssetReport["inspectionReport"] = Array.isArray(newReport?.inspectionReport)
      ? newReport?.inspectionReport
      : []

    const hasRepairRequests =
      inspectionReports &&
      inspectionReports?.some(
        (inspectionReport) => inspectionReport?.type === "y/n" && inspectionReport.input === "false"
      )
    const repairRequests: Data[] = []
    if (inspectionReports && hasRepairRequests) {
      inspectionReports.forEach((inspectionReport) => {
        if (inspectionReport?.type === "y/n" && inspectionReport.input === "false") {
          repairRequests.push({
            assetReportId: inspectionReport.id ?? "",
            assetId,
            inspectionFieldId: inspectionReport.id ?? "",
            label: inspectionReport.label ?? "",
            organizationId,
            reporterId,
            updatedAt: new Date(),
            __typename: "AssetRepairRequest",
          })
        }
      })
    }

    cache
      .inspectFields("Query")
      .filter((query) => fieldsToUpdate[query.fieldName])
      .forEach((query) => {
        if (query.fieldName === "assetRepairRequests") {
          addManyToQuery(cache, query, repairRequests)
        } else {
          addToQuery(cache, query, newReport)
        }
      })

    const status = reportArg.statusChange?.status ?? "Available"
    const active = reportArg.statusChange?.active ?? true
    // update parent asset with the lastInventoriedAt data
    cache.writeFragment(
      gql`
        fragment _ on Asset {
          id
          lastInventoriedAt
          repairRequestCount
          status
          active
          __typename
        }
      `,
      {
        id: newReport.assetId,
        lastInventoriedAt: newReport.createdAt,
        status: status,
        active: active,
        _typename: "Asset",
      }
    )
    if (hasRepairRequests) {
      cache.invalidate("Asset", "repairRequestCount", { id: assetId })
    }

    return newReport
  })
}
