import { ButtonFilled } from "./ButtonFilled"
import { ButtonHollow } from "./ButtonHollow"
import { H1 } from "./H1"
import { H2 } from "./H2"
import { H3 } from "./H3"
import { H4 } from "./H4"
import { H5 } from "./H5"
import { Input } from "./Input"
import { Label } from "./Label"
import { P } from "./P"

export { ButtonFilled, ButtonHollow, H1, H2, H3, H4, H5, Input, Label, P }
