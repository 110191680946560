var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4cf421f3991af8781007d9e45b6d250dfa83feb8"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init, browserTracingIntegration, replayIntegration } from "@sentry/nextjs"

const isProd = process.env.NODE_ENV === "production"
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const debug = !!process.env.SENTRY_DEBUG

const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT

if (SENTRY_DSN) {
  const tracesSampleRate = +process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE || 1.0 // Defaulted high to support initial performance investigations

  init({
    dsn: SENTRY_DSN || "",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: isProd ? tracesSampleRate : 1.0,
    integrations: [browserTracingIntegration(), replayIntegration({ maskAllInputs: false, maskAllText: false })],

    // Session Replay rates
    replaysSessionSampleRate: isProd ? tracesSampleRate : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    environment: SENTRY_ENVIRONMENT,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    debug,
  })
}
