import { DeprecatedTabGroup, Tab } from "../../components/deprecated/TabGroup"

const tabs: Tab[] = [
  {
    label: "Details",
    href: "/organization/details",
  },
  {
    label: "Divisions",
    href: "/organization/divisions",
    requiredPermission: "divisions:update",
    requiredFeatureFlag: "Divisions",
  },
  {
    label: "Admins",
    href: "/organization/admins",
    requiredPermission: "fe-organizationAdmins:read",
  },
  {
    label: "Roles",
    href: "/organization/roles",
    requiredPermission: "fe-organizationRoles:read",
  },
  {
    label: "Settings",
    href: "/organization/settings",
    requiredPermission: "organization:update",
  },
]

const Tabs = () => {
  return <DeprecatedTabGroup tabs={tabs} />
}
export default Tabs
