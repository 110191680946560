import { clsx } from "clsx"
import { FC } from "react"

type Props = {
  className?: string
}

export const SkeletonTextSmall: FC<Props> = ({ className }) => (
  <div className={clsx("h-3 w-32 bg-gray-200 rounded-full", className)} />
)
